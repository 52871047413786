import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, ZoomControl, useMapEvents, Marker, Rectangle } from 'react-leaflet';
import L from 'leaflet';

import HeatmapCell from "./HeatmapCell";

import "./map.css"
import "leaflet/dist/leaflet.css";

import Pointicon from './../../assets/icons/point_icon.svg';
import SpillPolygon from "./SpillPolygon";

const mapURL = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"



function MyComponent({clicks, setClicks, setLat1, setLng1, setLat2, setLng2, setMouseLat, setMouseLng, setMouseOverMap}) {
  const map = useMapEvents({
    mousemove: (e) => {
      setMouseLat(parseFloat(e.latlng.lat).toFixed(3));
      setMouseLng(parseFloat(e.latlng.lng).toFixed(3));
    },
    mouseover: () => {
      setMouseOverMap(true);
    },
    mouseout: () => {
      setMouseOverMap(false);
    },
    click: (e) => {
      if (clicks > 0) {
        if (clicks === 2) {
          setLat1(parseFloat(e.latlng.lat).toFixed(3));
          setLng1(parseFloat(e.latlng.lng).toFixed(3));
        } else if (clicks === 1) {
          setLat2(parseFloat(e.latlng.lat).toFixed(3));
          setLng2(parseFloat(e.latlng.lng).toFixed(3));
        }
        setClicks(clicks - 1);
      }
    },
  })
  return null
}



function Map({ showHeatmap, showSearchResults, clicks, setClicks, lat1, setLat1, lng1, setLng1, lat2, setLat2, lng2, setLng2, heatmapData, resultData, highlightedID, setHighlightedID, focusedID, setFocusedID, searchData, heatmapMaxIntensity, satelliteFilter, srcTypeFilter, classFilter }) {

  const mapRef = useRef(null);
  const [canRenderMap, setCanRenderMap] = useState(true);

  useEffect(() => {
    if (showSearchResults === true && resultData.length > 0) {

      const latCenter = (parseFloat(lat1) + parseFloat(lat2)) / 2;
      const lngCenter = (parseFloat(lng1) + parseFloat(lng2)) / 2;

      const latDim = Math.abs(parseFloat(lat1) - parseFloat(lat2));
      const lngDim = Math.abs(parseFloat(lng1) - parseFloat(lng2));

      if (mapRef.current) {
        
        const zoomLevel = Math.min(
          Math.floor(Math.log2(360 / lngDim)) + 1,
          Math.floor(Math.log2(180 / latDim)) + 1
        );

        const adjustedZoomLevel = zoomLevel * 1.2;

        setTimeout(() => {
          mapRef.current.invalidateSize();
          mapRef.current.flyTo([latCenter, lngCenter], adjustedZoomLevel, {
            duration: 0.5,
          });
        }, 500);

      }
    }
  }, [showSearchResults, resultData]);


  
  useEffect(() => {
    if (focusedID) {
      if (mapRef.current) {
        const map = mapRef.current;
        resultData.forEach((file) => {
          if (file._id === highlightedID) {

            let bounds;
            if (file._source.value.geometry.type === "Polygon") {
              bounds = L.latLngBounds(file._source.value.geometry.coordinates.map(point => [point[1], point[0]]));
            } else if (file._source.value.geometry.type === "MultiPolygon") {
              bounds = L.latLngBounds(file._source.value.geometry.coordinates.map(polygon => polygon.map(point => [point[1], point[0]])));  
            }
            
            const padding = [50, 50];
            const zoom = map.getBoundsZoom(bounds, false, padding);
            const center = [file._source.value.properties.center[1], file._source.value.properties.center[0]]

            // map.setView(center, zoom);
            map.invalidateSize();
            map.flyTo(center, zoom, {
              duration: 1,
            });
            
            setFocusedID(null);

          }
        });
      }
    }
  }, [focusedID]);



  const [mouseOverMap, setMouseOverMap] = useState(false);

  const [mouseLat, setMouseLat] = useState(0);
  const [mouseLng, setMouseLng] = useState(0);

  const customIcon = new L.Icon({
    iconUrl: Pointicon,
    iconSize: [16, 16], 
    iconAnchor: [8, 8], 
  });

  const divRef = useRef(null);

  const handleResize = () => {
    if (divRef.current) {
      if (divRef.current.offsetWidth > 0 && divRef.current.offsetHeight > 0) {
        setCanRenderMap(true);
      } else {
        setCanRenderMap(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <div className="map-div" ref={divRef} >
      {canRenderMap &&
        <MapContainer
          center={[0, 0]}
          zoom={3}
          zoomControl={false}
          minZoom={2}
          maxZoom={35}
          maxBounds={[
            [90, -Infinity], 
            [-90, Infinity],
          ]}
          style={{ height: '100%', width: '100%' }}
          attributionControl={false}
          ref={mapRef}
        >

          <TileLayer url={mapURL}/>

          <ZoomControl position="topright" />

          { heatmapData && showHeatmap === true && heatmapData.map((file, index) => (
            <React.Fragment key={index}>
              { file._source.spills.length > 0 && <HeatmapCell
                lat={file._source.lat}
                lon={file._source.lon}
                counter={file._source.spills.length}
                maxCounter={heatmapMaxIntensity}
                setLat1={setLat1}
                setLng1={setLng1}
                setLat2={setLat2}
                setLng2={setLng2}
                searchData={searchData}
              />}
            </React.Fragment>
          ))}

          { clicks > 0 && (
            <>
              <MyComponent
                clicks={clicks}
                setClicks={setClicks}
                setLat1={setLat1}
                setLng1={setLng1}
                setLat2={setLat2}
                setLng2={setLng2}
                setMouseLat={setMouseLat}
                setMouseLng={setMouseLng}
                setMouseOverMap={setMouseOverMap}
              />
              {mouseOverMap && (
                <Marker position={[mouseLat, mouseLng]} icon={customIcon} />
              )}
            </>
          )}

          {lat1 !== null && lng1 !== null && (
            <Marker position={[lat1, lng1]} icon={customIcon} />
          )}
          {lat2 !== null && lng2 !== null && (
            <Marker position={[lat2, lng2]} icon={customIcon} />
          )}
          {lat1 !== null && lng1 !== null && lat2 === null && lng2 === null && (
            <Rectangle bounds={[[lat1, lng1], [mouseLat, mouseLng]]} opacity={0.2} className="selection-rectangle" />
          )}
          {lat1 !== null && lng1 !== null && lat2 !== null && lng2 !== null && (
            <Rectangle bounds={[[lat1, lng1], [lat2, lng2]]} className="selection-rectangle" />
          )}

          {
            resultData && resultData.map((file, index) => (
              (file._id !== highlightedID) && <React.Fragment key={index}>
                <SpillPolygon
                  className="spill-polygon"
                  key={index}
                  type={file._source.value.geometry.type}
                  coordinates={file._source.value.geometry.coordinates}
                  id={file._id}
                  isHighlighted={file._id === highlightedID}
                  setHighlightedID={setHighlightedID}
                />
              </React.Fragment>
            ))
          }

          {
            resultData && resultData.map((file, index) => (
              (file._id === highlightedID) && <React.Fragment key={index}>
                <SpillPolygon
                  className="spill-polygon"
                  key={index}
                  type={file._source.value.geometry.type}
                  coordinates={file._source.value.geometry.coordinates}
                  id={file._id}
                  isHighlighted={file._id === highlightedID}
                  setHighlightedID={setHighlightedID}
                />
              </React.Fragment>
            ))
          }
                  
        </MapContainer>
      }

    </div>
  );
}



export default Map;