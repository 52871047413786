import React, { useState, useEffect } from "react";
import Collapsible from 'react-collapsible';

import ArrowUpIcon from './../../assets/icons/arrow_up_icon.svg';
import ArrowDownIcon from './../../assets/icons/arrow_down_icon.svg';

import { backendUrl } from "../../backendUrl";

const metadataMapping = require("./../../assets/observationsMetadataMappings/observationsMetadataMappings.js");



function OceanAdvancedSearch({ isVisible, setVisibility, minArea, setMinArea, maxArea, setMaxArea, satellite, setSatellite, srcType, setSrcType, minVolume, setMinVolume, maxVolume, setMaxVolume }) {
  
  const [satellitesMapping, setSatellitesMapping] = useState([]);
  const [srcTypesMapping, setSrcTypesMapping] = useState([]);

  function getSatellitesMapping() {
    fetch(backendUrl + '/api/v1/catalog/satellites-mapping')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Satellites mapping data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setSatellitesMapping(data.satellites_mapping)
      })
      .catch(error => {
        alert(error);
      });
  }

  function getSrcTypesMapping() {
    fetch(backendUrl + '/api/v1/catalog/src-type-mapping')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Source type mapping data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setSrcTypesMapping(data.source_type_mapping)
      })
      .catch(error => {
        alert(error);
      });
  }

  useEffect(() => {
    getSatellitesMapping();
    getSrcTypesMapping();
  }, []);
  
  return (
    <div className="form-group-div advanced">
      <Collapsible
        trigger={
        <span
          onClick={() => setVisibility(!isVisible)}
        >
          <div className="advanced-search-label-div">
            <p>Advanced Search</p>
            {isVisible ? 
              <i>
                <img src={ArrowUpIcon} alt='arrow-up-icon'/>
              </i>
            :
              <i>
                <img src={ArrowDownIcon} alt='arrow-down-icon'/>
              </i>
            }
          </div>
        </span>
        }
        open={isVisible}
        transitionTime={200}
      >
        <div className="form-group-div">
          <div className="form-field-div">
            <label >OIL AREA (km<sup>2</sup>)</label>
            <div className="input-div">
              <input type="number" placeholder="min" id="min-area" min="0" max="1000000" step={1} value={minArea === null ? '' : minArea} onChange={(event) => setMinArea(event.target.value)} />
              <input type="number" placeholder="max" id="max-area" min="0" max="1000000" step={1} value={maxArea === null ? '' : maxArea} onChange={(event) => setMaxArea(event.target.value)} />
            </div>
          </div>
          <div className="form-field-div">
            <label >OIL VOLUME (tn/m<sup>2</sup>)</label>
            <div className="input-div">
              <input type="number" placeholder="min" id="min-volume" min="0" max="1000000" step={1} value={minVolume === null ? '' : minVolume} onChange={(event) => setMinVolume(event.target.value)} />
              <input type="number" placeholder="max" id="max-volume" min="0" max="1000000" step={1} value={maxVolume === null ? '' : maxVolume} onChange={(event) => setMaxVolume(event.target.value)} />
            </div>
          </div>
          <div className="form-field-div">
            <label >SRC TYPE</label>
            <div className="input-div">
              <select
                id="src-type"
                value={srcType === null ? '' : srcType}
                onChange={(event) => setSrcType(event.target.value)}
              >
                <option value="">Select an option</option>
                {srcTypesMapping.map((source, index) => {
                  return <option key={index} value={source.source_type}>{metadataMapping.fromSrcTypeToLabel(source.source_type)} ({source.count})</option>
                })}
              </select>
            </div>
          </div>
          <div className="form-field-div">
            <label >SATELLITE</label>
            <div className="input-div">
              <select
                id="satellite"
                value={satellite === null ? '' : satellite}
                onChange={(event) => setSatellite(event.target.value)}
              >
                <option value="">Select an option</option>
                {satellitesMapping.map((satellite, index) => {
                  return <option key={index} value={satellite.sensor}>{satellite.sensor} ({satellite.count})</option>
                })}
              </select>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
}



export default OceanAdvancedSearch;  