function DateForm({ startingDate, setStartingDate, endingDate, setEndingDate }) {
  return (
    <div className="form-group-div">
      
      <div className="form-field-div">
        <label >STARTING-DATE</label>
        <div className="date-input-div">
          <input type="date" id="starting-date" value={startingDate === null ? '' : startingDate} onChange={(event) => setStartingDate(event.target.value)} />
        </div>
      </div>
      
      <div className="form-field-div">
        <label >ENDING-DATE</label>
        <div className="date-input-div">
          <input type="date" id="ending-date" value={endingDate === null ? '' : endingDate} onChange={(event) => setEndingDate(event.target.value)} />
        </div>
      </div>

    </div>
  );
}

export default DateForm;
