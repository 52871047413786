import React, { useState, useRef } from "react";

import GalleryItem from "./GalleryItem";

import "./gallery.css";


function Gallery({ isVisible, data, averageArea, totalArea, highlightedID, setHighlightedID, focusedID, setFocusedID }) {

  const [isDragging, setIsDragging] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const containerRef = useRef(null);
  
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setScrollY(e.clientY);
  };
  
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    const deltaY = e.clientY - scrollY;
    containerRef.current.scrollTop -= deltaY;
    setScrollY(e.clientY);
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
  };



  return (
    isVisible && (
    <div className="gallery-div">
      <div className="results-title-div">
        <h2 className="results-title">Search results:</h2>
      </div>
      <div className="results-stats-div">
        <p>The search has produced <b>{data.length}</b> results</p>
        <p>Total area: <b>{parseFloat(totalArea).toFixed(3)} km&sup2;</b></p>
        <p>Average area: <b>{parseFloat(averageArea).toFixed(3)} km&sup2;</b></p>
      </div>

      <div
        className="results-div"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >

        <div className="scrollbar-content-div" >
          {data.map((file, index) => (
            <React.Fragment key={index}>
              <GalleryItem
                id={file._id}
                identifier={file._source.identifier}
                acqDate={file._source.value.properties.acq_date}
                acqTime={file._source.value.properties.acq_time}
                area={file._source.value.properties.area}
                type={file._source.value.geometry.type}
                coordinates={file._source.value.geometry.coordinates}
                center={file._source.value.properties.center}
                isHighlighted={file._id === highlightedID}
                setHighlightedID={setHighlightedID}
                setFocusedID={setFocusedID}
              />
            </React.Fragment >
          ))}
        </div>
      
      </div>

    </div>
    )
  );
}



export default Gallery;