// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item-div:hover{
    background-color: rgba(166, 167, 156, 0.3)
}

.menu-item-div.active{
    background-color: rgba(166, 167, 156, 0.5)
}

.menu-item-div i{
    padding: 10%;
    display: flex;
    border-bottom: 1px solid var(--dark_brown);
    cursor: pointer;
}

.menu-item-div img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1023px) {
    .menu-item-div i{
        border: 0;
        border-right: 1px solid var(--dark_brown);
        width: 100%;
        height: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/side_menu/menuItem.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,SAAS;QACT,yCAAyC;QACzC,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".menu-item-div:hover{\r\n    background-color: rgba(166, 167, 156, 0.3)\r\n}\r\n\r\n.menu-item-div.active{\r\n    background-color: rgba(166, 167, 156, 0.5)\r\n}\r\n\r\n.menu-item-div i{\r\n    padding: 10%;\r\n    display: flex;\r\n    border-bottom: 1px solid var(--dark_brown);\r\n    cursor: pointer;\r\n}\r\n\r\n.menu-item-div img{\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n@media screen and (max-width: 1023px) {\r\n    .menu-item-div i{\r\n        border: 0;\r\n        border-right: 1px solid var(--dark_brown);\r\n        width: 100%;\r\n        height: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
