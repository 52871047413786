import "./menuItem.css"



function MenuItem({label, icon, action, isOpen, handleOpen, handleClose}) {
  return (
    <div className={`menu-item-div ${isOpen ? 'active' : ''}`}>
      
      <i onClick={() => {
        if (action) {
          action();
        } else {
          isOpen ? handleClose() : handleOpen();
        }
      }}>
        <img src={icon} alt={label} />
      </i>

    </div>
  );
}



export default MenuItem;