import React, { useState, useEffect } from "react";

import Table from "../../components/catalog_table/Table";
import CatalogSearch from "../../components/catalog_search/CatalogSearch";
import CatalogStatistics from "../../components/catalog_statistics/CatalogStatistics";
import ModelStatistics from "../../components/model_statistics/ModelStatistics";
import LoadingCircle from "../../components/loading/LoadingCircle";
import Overlay from "../../components/loading/Overlay";
import Menu from "../../components/side_menu/Menu";

import { backendUrl } from "../../backendUrl";

import "./catalogPage.css";



function CatalogPage() {

  const [catalogData, setCatalogData] = useState([]);
  const [catalogLength, setCatalogLength] = useState(0);
  const [page, setPage] = useState(1);
  const rowsToShow = 20;

  const [accessorSorted, setAccessorSorted] = useState(null);
  const [order, setOrder] = useState(null);

  const [lat1, setLat1] = useState(null);
  const [lng1, setLng1] = useState(null);
  const [lat2, setLat2] = useState(null);
  const [lng2, setLng2] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [minArea, setMinArea] = useState(null);
  const [maxArea, setMaxArea] = useState(null);
  const [satellite, setSatellite] = useState(null);
  const [srcType, setSrcType] = useState(null);
  const [minVolume, setMinVolume] = useState(null);
  const [maxVolume, setMaxVolume] = useState(null);

  const [filterLabel, setFilterLabel] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isCatalogSelected, setIsCatalogSelected] = useState(true);
  const [isCatalogSearchSelected, setIsCatalogSearchSelected] = useState(false);
  const [isCatalogStatisticsSelected, setIsCatalogStatisticsSelected] = useState(false);
  const [isModelStatisticsSelected, setIsModelStatisticsSelected] = useState(false);

  const [menuState, setMenuState] = useState("closed")

  function getCatalogData(accessorSorted, order) {
    setIsLoading(true);
    
    let fetchString = backendUrl + `/api/v1/catalog?page=${page}&itemsToShow=${rowsToShow}`;

    if (minArea !== null) fetchString += `&minArea=${minArea}`
    if (maxArea !== null) fetchString += `&maxArea=${maxArea}`
    if (minVolume !== null) fetchString += `&minVolume=${minVolume}`
    if (maxVolume !== null) fetchString += `&maxVolume=${maxVolume}`
    if (startingDate !== null) fetchString += `&startingDate=${startingDate}`
    if (endingDate !== null) fetchString += `&endingDate=${endingDate}`
    if (satellite !== null) fetchString += `&satellite=${satellite}`
    if (srcType !== null) fetchString += `&srcType=${srcType}`

    if (accessorSorted !== null && order !== null) {
      fetchString += `&sort=${accessorSorted}&order=${order}`;
    }

    fetch(fetchString)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 400) {
          throw new Error('Bad Request');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setCatalogData(data.data)
        setCatalogLength(data.length)
        setIsLoading(false);
      })
      .catch(error => {
        alert(error);
        setIsLoading(false);
      });
  }

  function handleSortingChange(accessor) {
    if (accessor !== "area" && accessor !== "min_volume" && accessor !== "max_volume" && accessor !== "acq_date" ){
      return;
    }
    const newOrder = order === "asc" ? "desc" : "asc";
    if (accessor === accessorSorted) {
      setOrder(newOrder);
    } else {
      setAccessorSorted(accessor);
      setOrder("asc");
    }
  };

  function handleSelectCatalog() {
    setIsCatalogSelected(true);
    setIsCatalogSearchSelected(false);
    setIsCatalogStatisticsSelected(false);
    setIsModelStatisticsSelected(false);
  }

  function handleSelectCatalogStatistics() {
    setIsCatalogSelected(false);
    setIsCatalogSearchSelected(false);
    setIsCatalogStatisticsSelected(true);
    setIsModelStatisticsSelected(false);
  }

  function handleSelectModelStatistics() {
    setIsCatalogSelected(false);
    setIsCatalogSearchSelected(false);
    setIsCatalogStatisticsSelected(false);
    setIsModelStatisticsSelected(true);
  }

  function handleSelectCatalogSearch() {
    setIsCatalogSelected(false);
    setIsCatalogSearchSelected(true);
    setIsCatalogStatisticsSelected(false);
    setIsModelStatisticsSelected(false);
  }

  function writeFilterLabel(){
    let labelString = ""
    if (minArea !== null) labelString += ` minArea=${minArea}`
    if (maxArea !== null) labelString += ` maxArea=${maxArea}`
    if (minVolume !== null) labelString += ` minVolume=${minVolume}`
    if (maxVolume !== null) labelString += ` maxVolume=${maxVolume}`
    if (startingDate !== null) labelString += ` startingDate=${startingDate}`
    if (endingDate !== null) labelString += ` endingDate=${endingDate}`
    if (satellite !== null) labelString += ` satellite=${satellite}`
    if (srcType !== null) labelString += ` srcType=${srcType}`
    if (labelString !== "") labelString = "Active filters: " + labelString
    setFilterLabel(labelString)
  }

  function handleCataloSearch(){
    setPage(1);
    setAccessorSorted(null);
    setOrder(null);
    handleSelectCatalog()
    getCatalogData(null, null)
  }

  function handleSearchReset(){
    setPage(1);
    setAccessorSorted(null);
    setOrder(null);

    setLat1(null);
    setLng1(null);
    setLat2(null);
    setLng2(null);
    setStartingDate(null);
    setEndingDate(null);
    setMinArea(null);
    setMaxArea(null);
    setMinVolume(null);
    setMaxVolume(null);
    setSatellite(null);
    setSrcType(null);
  }

  useEffect(() => {
    writeFilterLabel()
    if (lat1 === null && lat2 === null && lng1 === null && lng2 === null && startingDate === null && endingDate === null && minArea === null && maxArea === null && minVolume === null && maxVolume === null && satellite === null && srcType === null) {
      getCatalogData(null, null)
    }
  }, [lat1, lat2, lng1, lng2, minArea, maxArea, minVolume, maxVolume, startingDate, endingDate, satellite, srcType])



  useEffect(() => {
    getCatalogData(null, null);
  }, []);

  useEffect(() => {
    getCatalogData(accessorSorted, order);

  }, [page, accessorSorted, order]);



  return (
    <>

      {isLoading && (
        <>
          <Overlay/>
          <LoadingCircle text={"Loading the catalog..."}/>
        </>
      )}

      <Menu
        modality={"catalog"}
        menuState={menuState}
        setMenuState={setMenuState}
        handleSelectCatalog={handleSelectCatalog}
        handleSelectCatalogSearch={handleSelectCatalogSearch}
        handleSelectCatalogStatistics={handleSelectCatalogStatistics}
        handleSelectModelStatistics={handleSelectModelStatistics}
      />

      <div className="catalog-div">
        <div className="catalog-content-div">
          {isCatalogSelected && (
            <Table
              catalogData={catalogData}
              catalogLength={catalogLength}
              page={page}
              setPage={setPage}
              rowsToShow={rowsToShow}
              accessorSorted={accessorSorted}
              setAccessorSorted={setAccessorSorted}
              order={order}
              setOrder={setOrder}
              handleSortingChange={handleSortingChange}
              filterLabel={filterLabel}
            />
          )}
          {isCatalogSearchSelected && (
            <CatalogSearch
              searchData={handleCataloSearch}
              resetData={handleSearchReset}
              lat1={lat1}
              setLat1={setLat1}
              lng1={lng1}
              setLng1={setLng1}
              lat2={lat2}
              setLat2={setLat2}
              lng2={lng2}
              setLng2={setLng2}
              startingDate={startingDate}
              setStartingDate={setStartingDate}
              endingDate={endingDate}
              setEndingDate={setEndingDate}
              minArea={minArea}
              setMinArea={setMinArea}
              maxArea={maxArea}
              setMaxArea={setMaxArea}
              satellite={satellite}
              setSatellite={setSatellite}
              srcType={srcType}
              setSrcType={setSrcType}
              minVolume={minVolume}
              setMinVolume={setMinVolume}
              maxVolume={maxVolume}
              setMaxVolume={setMaxVolume}
            />
          )}
          {isCatalogStatisticsSelected && (
            <CatalogStatistics setIsLoading={setIsLoading}/>
          )}
          {isModelStatisticsSelected && (
            <ModelStatistics setIsLoading={setIsLoading}/>
          )}
        </div>
      </div> 

    </>
  );
}



export default CatalogPage;