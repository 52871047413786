// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-div {
    border-radius: 0.3em;
    border: 2px solid var(--darker_brown);
}

.catalog-search-head {
    background-color: var(--dark_brown);
    border-bottom: 2px solid var(--darker_brown);
}

.catalog-search-head h1 {
    color: var(--darker_brown2);
    font-size: 1.5em;
    padding: 8px 20px;
}

.catalog-search-content {
    background: var(--light_brown);
    padding: 1%;
}

.catalog-search-content p {
    margin: 0.5em;
    border-bottom: 1px solid #ddd;
}

.label {
    padding-right: 0.5em;
    border-radius: 0.2em;
    font-size: 1.2em;
    font-weight: 500;
}

.catalog-search-div {
    /* border: 3px solid red; */
    width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog_search/catalogSearch.css"],"names":[],"mappings":"AAEA;IACI,oBAAoB;IACpB,qCAAqC;AACzC;;AAEA;IACI,mCAAmC;IACnC,4CAA4C;AAChD;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,UAAU;AACd","sourcesContent":["@import url(\"../../assets/colors.css\");\r\n\r\n.search-div {\r\n    border-radius: 0.3em;\r\n    border: 2px solid var(--darker_brown);\r\n}\r\n\r\n.catalog-search-head {\r\n    background-color: var(--dark_brown);\r\n    border-bottom: 2px solid var(--darker_brown);\r\n}\r\n\r\n.catalog-search-head h1 {\r\n    color: var(--darker_brown2);\r\n    font-size: 1.5em;\r\n    padding: 8px 20px;\r\n}\r\n\r\n.catalog-search-content {\r\n    background: var(--light_brown);\r\n    padding: 1%;\r\n}\r\n\r\n.catalog-search-content p {\r\n    margin: 0.5em;\r\n    border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.label {\r\n    padding-right: 0.5em;\r\n    border-radius: 0.2em;\r\n    font-size: 1.2em;\r\n    font-weight: 500;\r\n}\r\n\r\n.catalog-search-div {\r\n    /* border: 3px solid red; */\r\n    width: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
