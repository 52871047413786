import React, { useState } from "react";

import RectangleSelector from "./rectangle_selector/RectangleSelector";
import LocationForm from "./LocationForm";
import DateForm from "./DateForm";
import OceanAdvancedSearch from "./OceanAdvancedSearch";

import "./../../assets/form.css"
import "./../../assets/formButtons.css"



function SearchForm({modality, searchData, resetData, locationRequired, ...props}) {

  const [rectangleMode, setRectangleMode] = useState(false);

  function handleRectangleModeClick() {
    if (rectangleMode) {
      props.setClicks(0);
      setRectangleMode(false);
    } else {
      props.setLat1(null);
      props.setLng1(null);
      props.setLat2(null);
      props.setLng2(null);
      setRectangleMode(true);
      props.setClicks(2);
    }
  }

  const [isVisible, setVisibility] = useState(false);

  function handleFormSubmit(event) {
    event.preventDefault();
    if (locationRequired && !(props.lat1 && props.lng1 && props.lat2 && props.lng2) ) {
      alert("Please select a location");
    } else {
      searchData(props.lat2, props.lat1, props.lng1, props.lng2, true);
    }
  }

  function handleReset(event) {
    event.preventDefault();
    resetData();
  }


  
  return (
      <>
        <form onSubmit={handleFormSubmit} onReset={handleReset}>

        {(modality === 'ocean' || modality === 'coasts') && (
          <RectangleSelector
            clicks={props.clicks}
            handleRectangleModeClick={handleRectangleModeClick}
          />
         )}
        
        <LocationForm
          clicks={props.clicks}
          setClicks={props.setClicks}
          lat1={props.lat1}
          setLat1={props.setLat1}
          lng1={props.lng1}
          setLng1={props.setLng1}
          lat2={props.lat2}
          setLat2={props.setLat2}
          lng2={props.lng2}
          setLng2={props.setLng2}
        />
        <DateForm
          startingDate={props.startingDate}
          setStartingDate={props.setStartingDate}
          endingDate={props.endingDate}
          setEndingDate={props.setEndingDate}
        />

        {( modality === 'ocean' || modality === "catalog") && (
          <>
            <OceanAdvancedSearch
              isVisible={isVisible}
              setVisibility={setVisibility}
              minArea={props.minArea}
              setMinArea={props.setMinArea}
              maxArea={props.maxArea}
              setMaxArea={props.setMaxArea}
              satellite={props.satellite}
              setSatellite={props.setSatellite}
              srcType={props.srcType}
              setSrcType={props.setSrcType}
              minVolume={props.minVolume}
              setMinVolume={props.setMinVolume}
              maxVolume={props.maxVolume}
              setMaxVolume={props.setMaxVolume}
              />
          </>
        )}

        <div className="form-buttons-div">
          <button className="search-button" type="submit">SEARCH</button>
          <button className="reset-button" type="reset">RESET</button>
        </div>
      
      </form>
    </>
  );
}



export default SearchForm;