import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, ZoomControl, Rectangle, Polyline, Polygon, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import ContextMenu from "./ContextMenu";

import "leaflet/dist/leaflet.css";
import "./analysisMap.css";

const mapURL = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"



function AnalysisMap({ observation, simulationFrame, nextEvent, deltaTime, pathPoints, value, handleNextEventClick }) {

  const mapRef = useRef(null);
  const nextEventRef = useRef();

  function getColor(min, max, intensity) {
    const range = max - min;
    const red = 255;
    const blue = 0;
    const normalizedIntensity = (intensity - min) / range;
    const green = Math.round((1 - normalizedIntensity) * 255);
    return `rgb(${red}, ${green}, ${blue})`;
  }

  function getColorForCoasts(min, max, intensity) {
    const range = max - min;
    const blue = Math.round((intensity - min) / range * 255);
    const red = Math.round(255 - blue);
    const green = 0;
    return `rgb(${red}, ${green}, ${blue})`;
  }

  function focusOnObservation() {
    if (observation) {
      if (mapRef.current) {
        const map = mapRef.current;
        map.invalidateSize();

        let bounds;
        if (observation._source.value.geometry.type === "Polygon") {
          bounds = L.latLngBounds(observation._source.value.geometry.coordinates.map(point => [point[1], point[0]]));
        } else if (observation._source.value.geometry.type === "MultiPolygon") {
          bounds = L.latLngBounds(observation._source.value.geometry.coordinates.map(polygon => polygon.map(point => [point[1], point[0]])));
        }

        const padding = [400, 400];
        const center = [observation._source.value.properties.center[1], observation._source.value.properties.center[0]];
        const zoom = map.getBoundsZoom(bounds, false, padding);

        map.flyTo(center, zoom, {
          duration: 1,
        });
      }
    }
  }

  // function focusOnSimulation() {

  //   if (border) {
  //     if (mapRef.current) {
  //       const map = mapRef.current;
  //       map.invalidateSize();

  //       let bounds = L.latLngBounds(border)

  //       const padding = [400, 400];
  //       const center = bounds.getCenter();
  //       const zoom = map.getBoundsZoom(bounds, false, padding);

  //       map.flyTo(center, zoom, {
  //         duration: 1,
  //       });
  //     }
  //   }
  // }



  useEffect(() => {
    focusOnObservation();
  }, [observation]);

  useEffect(() => {
    if (nextEventRef.current) {
      nextEventRef.current.bringToBack();
    }
  }, [nextEvent]);



  return (
    <div className="analysis-map">

      <MapContainer
        center={[0, 0]}
        zoom={3}
        zoomControl={false}
        minZoom={2}
        maxZoom={35}
        maxBounds={[
          [90, -Infinity], 
          [-90, Infinity],
        ]}
        style={{ height: '100%', width: '100%' }}
        attributionControl={false}
        ref={mapRef}
      >

        <TileLayer url={mapURL}/>
        <ZoomControl position="topright" />

        { observation && (
          <React.Fragment key="main-event">
            <Polygon
              className="analysis-polygon"
              positions={
                observation._source.value.geometry.type === "Polygon"
                ? observation._source.value.geometry.coordinates.map(point => [point[1], point[0]])
                : observation._source.value.geometry.type === "MultiPolygon"
                ? observation._source.value.geometry.coordinates.map(polygon => polygon.map(point => [point[1], point[0]]))
                : []
              }
            >
              <Tooltip sticky={true}>Spill observation</Tooltip>
            </Polygon>
          </React.Fragment>
        )}

        {simulationFrame && simulationFrame.oil?.oilValues && value > 0 && simulationFrame.oil.oilValues.map((item, index) => {
          const halfSideLength = 0.0013636364 / 2;
          const topLeft = [item[1] - halfSideLength, item[0] - halfSideLength];
          const bottomRight = [item[1] + halfSideLength, item[0] + halfSideLength];

          return (
            <React.Fragment key={index}>
              <Rectangle
                bounds={[topLeft, bottomRight]}
                fillColor={getColor(simulationFrame.oil.metadata.minValue, simulationFrame.oil.metadata.maxValue, item[2])}
                pathOptions={{
                  fillOpacity: 0.8,
                  color: "red",
                  weight: 0,
                  opacity: 1,
                  smoothFactor: 0.1
                }}
              />
            </React.Fragment>
          );
        })}

        {simulationFrame && simulationFrame.beached?.beachedValues && value > 0 && simulationFrame.beached.beachedValues.map((item, index) => {
          const halfSideLength = 0.0013636364 / 2;
          const topLeft = [item[1] - halfSideLength, item[0] - halfSideLength];
          const bottomRight = [item[1] + halfSideLength, item[0] + halfSideLength];

          return (
            <React.Fragment key={index}>
              <Rectangle
                bounds={[topLeft, bottomRight]}
                fillColor={getColorForCoasts(simulationFrame.beached.metadata.minValue, simulationFrame.beached.metadata.maxValue, item[2])}
                pathOptions={{
                  fillOpacity: 0.8,
                  color: "red",
                  weight: 0,
                  opacity: 1,
                  smoothFactor: 0.1
                }}
              />
            </React.Fragment>
          );
        })}

        {simulationFrame && value > 0 && (
          <>
            <Polyline
              positions={[
                [observation._source.value.properties.center[1], observation._source.value.properties.center[0]],
                [pathPoints[0][1], pathPoints[0][0]]
              ]}
              pathOptions={{
                color: "black",
                fillColor: "transparent",
                opacity: 0.5,
                weight: 0.75,
              }}
            />

            {pathPoints && pathPoints.length > 1 && (
              <>
                {pathPoints.map((point, index) => {
                  if (index < value - 1){
                    if (index < pathPoints.length - 1) {
                      const nextPoint = pathPoints[index + 1];
                      return (
                        <Polyline
                          key={index}
                          positions={[
                            [point[1], point[0]],
                            [nextPoint[1], nextPoint[0]],
                          ]}
                          pathOptions={{
                            color: "black",
                            fillColor: "transparent",
                            opacity: 0.5,
                            weight: 0.75,
                          }}
                        />
                      );
                    }
                  }
                  return null;
                })}
              </>
            )}

          </>

        )}

        {nextEvent && nextEvent !== null && (
          <React.Fragment key="next-event">
            <Polygon
              className="shadow-polygon"
              ref={nextEventRef}
              positions={
                nextEvent._source.value.geometry.type === "Polygon"
                ? nextEvent._source.value.geometry.coordinates.map(point => [point[1], point[0]])
                : nextEvent._source.value.geometry.type === "MultiPolygon"
                ? nextEvent._source.value.geometry.coordinates.map(polygon => polygon.map(point => [point[1], point[0]]))
                : []
              }
              eventHandlers={{
                click: () => {
                  handleNextEventClick()
                }
              }}
            >
              <Tooltip sticky={true}>Second observation of the satellite (+{deltaTime} hours)</Tooltip>
            </Polygon>
          </React.Fragment>
        )}

        <ContextMenu focusOnObservation={focusOnObservation}/>

      </MapContainer>

    </div>
  );
}



export default AnalysisMap;