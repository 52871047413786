import React, { useEffect, useRef, memo } from "react";
import { Polygon } from 'react-leaflet';

import "./../../assets/colors.css"


const SpillPolygon = memo(function SpillPolygon({ type, coordinates, id, isHighlighted, setHighlightedID }) {

  const polygonRef = useRef(null);

  useEffect(() => {
    if (isHighlighted) {
      polygonRef.current.setStyle({ fillColor: 'var(--purple)', color: 'var(--purple)' });
    } else {
      polygonRef.current.setStyle({ fillColor: 'var(--blue)', color: 'var(--blue)', opacity: 0.5});
    }
  }, [isHighlighted]);


  let positions;

  if (type === "Polygon") {
    positions = coordinates.map(point => [point[1], point[0]]);
  } else if (type === "MultiPolygon") {
    positions = coordinates.map(polygon => polygon.map(point => [point[1], point[0]]));
  } else {
    positions = [];
  }

  return (
    <Polygon
      ref={polygonRef}
      positions={positions}
      eventHandlers={{
        click: () => {
          setHighlightedID(id);
        }
      }}
    />
  );
}, (prevProps, nextProps) => {
  return (prevProps.isHighlighted === nextProps.isHighlighted);
});

export default SpillPolygon;