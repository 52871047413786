import React from "react";

import { FaSort } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";



function TableHead({ columns, handleSortingChange, accessorSorted, order }) {
  
  return (
    <thead>
      <tr>
        
        {columns.map(({ label, accessor, sortable }) => {
          return(
            <th
              key={accessor}
              onClick={sortable ? () => handleSortingChange(accessor) : null}
            >
              <div>
                {label}
                <span>
                  {accessor !== accessorSorted && sortable && <FaSort />}
                  {accessor === accessorSorted && order === "asc" && <FaSortUp />}
                  {accessor === accessorSorted && order === "desc" && <FaSortDown />}
                </span>
              </div>
            </th>
          )
        })}

        <th key={"analyze"}>
          Inspect
        </th>

      </tr>
    </thead>
  );
      
};
    
export default TableHead;