import React, {useState, useEffect} from "react";
import {useLocation} from 'react-router-dom';

import AnalysisMap from "../../components/analysis/AnalysisMap";
import Menu from "../../components/side_menu/Menu";
import Slider from "../../components/slider/Slider";

import Overlay from "../../components/loading/Overlay";
import LoadingCircle from "../../components/loading/LoadingCircle";
import SpillSelectionMenu from "../../components/spill_selection_menu/SpillSelectionMenu";

import { backendUrl } from "../../backendUrl";

import "./analysisPage.css";



function AnalysisPage() {

  const [id, setId] = useState(null);

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [observation, setObservation] = useState(null);
  const [isSimulationAvailable, setIsSimulationAvailable] = useState(false);
  const [simulationFrame, setSimulationFrame] = useState(null);
  const [pathPoints, setPathPoints] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);
  const [deltaTime, setDeltaTime] = useState(null);

  const [menuState, setMenuState] = useState("closed")

  const [value, setValue] = useState(0);
  
  const [fileToDownload, setFileToDownload] = useState(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  function getObservation(id) {
    setIsLoading(true);
    fetch(backendUrl + `/api/v1/oil-spills/${id}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Identifier not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setObservation(data.data)
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        alert(error.message);
      });
  }

  function checkSimulationAvailability(id) {
    fetch(backendUrl + `/api/v1/simulation/frame/${id}/0`)
      .then(response => {
        if (response.ok) {
          setIsSimulationAvailable(true);
          getNextEvent(id);
          getLinePath(id);
        } else {
          setIsSimulationAvailable(false);
        }
      })
      .catch(error => {
        setIsSimulationAvailable(false);
      });
  }

  function getSimulationFrame() {
    if (value > 0){
      fetch(backendUrl + `/api/v1/simulation/frame/${id}/${value-1}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 404) {
            throw new Error('Simulation Frame not found');
          } else {
            throw new Error('Internal Error');
          }
        })
        .then(data => {
          if (data.simulationAvailable === true){
            setSimulationFrame(data.data)
          }
        })
        .catch(error => {
          alert(error);
        });
    } else {
      setSimulationFrame(null);
    }
  }

  function getLinePath(id) {
    fetch(backendUrl + `/api/v1/simulation/line-path/${id}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Line path not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setPathPoints(data.data)
      })
      .catch(error => {
        alert(error.message);
      });
  }

  function getNextEvent(id) {
    fetch(backendUrl + `/api/v1/oil-spills/next-event/${id}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Next event not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setNextEvent(data.data)
        setDeltaTime(data.deltaTime)
      })
      .catch(error => {
        alert(error.message);
      });
  }

  function removeId() {
    setId(null);
    setObservation(null);
    setSimulationFrame(null);
    setPathPoints(null);
    setNextEvent(null);
  }

  function handleNextEventClick() {
    removeId();
    setId(nextEvent._source.identifier);
    getObservation(nextEvent._source.identifier);
    setValue(0);
    setIsSimulationAvailable(false);
    setDeltaTime(null);
  }

  useEffect(() => {
    const newId = location.state?.id;
    if (newId) {
      setId(newId);
      getObservation(newId);
    }
  }, []);

  useEffect(() => {
    if (id !== null) {
      checkSimulationAvailability(id);
    }
  }, [id]);

  useEffect(() => {
    getSimulationFrame();
  }, [value]);

  function handleSubmit(){
    if (id !== null) {
      getObservation(id);
      checkSimulationAvailability(id);
    }
  }

  const oil_center_lon = simulationFrame?.oil?.metadata?.center ? simulationFrame.oil.metadata.center[0]: null
  const oil_center_lat = simulationFrame?.oil?.metadata?.center ? simulationFrame.oil.metadata.center[1]: null
  const oil_total = simulationFrame?.oil?.metadata?.totalOil || null

  const beached_center_lon = simulationFrame?.beached?.metadata?.center ? simulationFrame.beached.metadata.center[0]: null
  const beached_center_lat = simulationFrame?.beached?.metadata?.center ? simulationFrame.beached.metadata.center[1]: null
  const beached_total = simulationFrame?.beached?.metadata?.totalBeached || null;


  
  return (
    <>
      { isLoading && (
        <>
          <Overlay/>
          <LoadingCircle text={"Loading the oil spill..."}/>
        </>
      )}

      {(!observation && !isLoading) && (
        <>
          <Overlay />
          <SpillSelectionMenu handleSubmit={handleSubmit} id={id} setId={setId} />
        </>
      )}

      <Menu
        modality="analysis"
        menuState={menuState}
        setMenuState={setMenuState}
        identifier={observation?._source.identifier}
        author={observation?._source.author}
        imageUrl={observation?._source.imageUrl}
        acqDate={observation?._source.value.properties.acq_date}
        acqTime={observation?._source.value.properties.acq_time}
        latCenter={observation?._source.value.properties.center ? parseFloat(observation._source.value.properties.center[1]).toFixed(6) : undefined}
        lngCenter={observation?._source.value.properties.center ? parseFloat(observation._source.value.properties.center[0]).toFixed(6) : undefined}
        area={observation?._source.value.properties.area}
        minVolume={observation?._source.value.properties.min_volume}
        maxVolume={observation?._source.value.properties.max_volume}
        class={observation?._source.value.properties.event_class}
        satellite={observation?._source.value.properties.sensor}
        srcType={observation?._source.value.properties.source_type}
        
        oil_center_lon = {oil_center_lon}
        oil_center_lat = {oil_center_lat}
        oil_total = {oil_total}

        beached_center_lon = {beached_center_lon}
        beached_center_lat = {beached_center_lat}
        beached_total = {beached_total}

        sim_metadata_not_available={value === 0}

        removeId={removeId}
      />

      <div className="analysis-map-div">
        <AnalysisMap
          observation={observation}
          simulationFrame={simulationFrame}
          nextEvent={nextEvent}
          deltaTime={deltaTime}
          pathPoints={pathPoints}
          value={value}
          handleNextEventClick={handleNextEventClick}
        />
        { isSimulationAvailable && (
          <Slider
            value={value}
            setValue={setValue}
            deltaTime={deltaTime}
          />
        )}
      </div>

      { isDownloadLoading && (
        <>
          <Overlay/>
          <LoadingCircle text={"Preparing the download..."}/>
        </>
      ) }

    </>
  );
}



export default AnalysisPage;