// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageController-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
}

.pageController-div div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0.5em;
}

.pageController-div button {
    width: 2em;
    padding: 0.2em;
    margin: 0 0.2em;
    border: 1px solid var(--darker_brown);
    background-color: var(--brown);
    border-radius: 0.3em;
    cursor: pointer;
}

.pageController-div button span i {
    font-size: 0.8em;
    color: var(--darker_brown);
}

.pageController-div button:hover {
    background-color: var(--brown2);
}

.pageController-div button:active {
    background-color: var(--dark_brown);
}`, "",{"version":3,"sources":["webpack://./src/components/catalog_table/page_controller/pageController.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,eAAe;IACf,qCAAqC;IACrC,8BAA8B;IAC9B,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":["@import url(\"../../../assets/colors.css\");\r\n\r\n.pageController-div {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin: 1em 0;\r\n}\r\n\r\n.pageController-div div {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    margin-left: 0.5em;\r\n}\r\n\r\n.pageController-div button {\r\n    width: 2em;\r\n    padding: 0.2em;\r\n    margin: 0 0.2em;\r\n    border: 1px solid var(--darker_brown);\r\n    background-color: var(--brown);\r\n    border-radius: 0.3em;\r\n    cursor: pointer;\r\n}\r\n\r\n.pageController-div button span i {\r\n    font-size: 0.8em;\r\n    color: var(--darker_brown);\r\n}\r\n\r\n.pageController-div button:hover {\r\n    background-color: var(--brown2);\r\n}\r\n\r\n.pageController-div button:active {\r\n    background-color: var(--dark_brown);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
