// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spill-selection-div {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 70px), -50%);
    z-index: 50;
    padding: 2em 5em;
    border-radius: 5px;
}

.spill-selection-div input {
    margin: 0.5em 0;
    color: var(--darker_brown);
    border: 1px solid var(--darker_brown);
    border-radius: 2px;
    padding: 0.2em 0.5em;
    margin-right: 0.5em;
}

.spill-selection-div .analyze-button {
    background-color: var(--dark_blue);
    border: 1px solid var(--darker_blue);
    color: white;
    cursor: pointer;
}

.spill-selection-div .analyze-button:hover {
    background-color: var(--blue);
}

.spill-selection-div .analyze-button:active {
    background-color: var(--light_blue);
}`, "",{"version":3,"sources":["webpack://./src/components/spill_selection_menu/spillSelectionMenu.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,QAAQ;IACR,SAAS;IACT,6CAA6C;IAC7C,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,qCAAqC;IACrC,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,oCAAoC;IACpC,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".spill-selection-div {\r\n    background-color: white;\r\n    position: fixed;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(calc(-50% + 70px), -50%);\r\n    z-index: 50;\r\n    padding: 2em 5em;\r\n    border-radius: 5px;\r\n}\r\n\r\n.spill-selection-div input {\r\n    margin: 0.5em 0;\r\n    color: var(--darker_brown);\r\n    border: 1px solid var(--darker_brown);\r\n    border-radius: 2px;\r\n    padding: 0.2em 0.5em;\r\n    margin-right: 0.5em;\r\n}\r\n\r\n.spill-selection-div .analyze-button {\r\n    background-color: var(--dark_blue);\r\n    border: 1px solid var(--darker_blue);\r\n    color: white;\r\n    cursor: pointer;\r\n}\r\n\r\n.spill-selection-div .analyze-button:hover {\r\n    background-color: var(--blue);\r\n}\r\n\r\n.spill-selection-div .analyze-button:active {\r\n    background-color: var(--light_blue);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
