import React, { useState, useEffect } from "react";
import Map from "./../../components/heatmap/Map";
import Menu from "../../components/side_menu/Menu";
import LoadingCircle from "../../components/loading/LoadingCircle";
import Overlay from "../../components/loading/Overlay";
import Gallery from "../../components/gallery/Gallery";

import { backendUrl } from "../../backendUrl";

import "./heatmapPage.css";



function HeatMapPage({ modality }) {

  const [heatmapData, setHeatmapData] = useState([]);
  const [heatmapMaxIntensity, setHeatmapMaxIntensity] = useState(0);
  const [isHeatMapLoading, setIsHeatMapLoading] = useState(true);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [resultData, setResultData] = useState([]);

  const [highlightedID, setHighlightedID] = useState(null);
  const [focusedID, setFocusedID] = useState(null);

  const [lat1, setLat1] = useState(null);
  const [lng1, setLng1] = useState(null);
  const [lat2, setLat2] = useState(null);
  const [lng2, setLng2] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [minArea, setMinArea] = useState(null);
  const [maxArea, setMaxArea] = useState(null);
  const [satellite, setSatellite] = useState(null);
  const [srcType, setSrcType] = useState(null);
  const [minVolume, setMinVolume] = useState(null);
  const [maxVolume, setMaxVolume] = useState(null);
  const [includeSimulations, setIncludeSimulations] = useState(null);
  const [clicks, setClicks] = useState(0);
  const [minBeached, setMinBeached] = useState(null);
  const [maxBeached, setMaxBeached] = useState(null);

  const [averageArea, setAverageArea] = useState(null);
  const [totalArea, setTotalArea] = useState(null);

  const [menuState, setMenuState] = useState("closed")

  const [satelliteFilter, setSatelliteFilter] = useState(null)
  const [srcTypeFilter, setSrcTypeFilter] = useState(null)
  const [classFilter, setClassFilter] = useState(null)

  function fetchData(satelliteFilter, srcTypeFilter, classFilter) {

    setIsHeatMapLoading(true)
    setHeatmapData([])

    let fetchAddress = backendUrl + '/api/v1/heatmap/'

    if (!(satelliteFilter === null && srcTypeFilter === null && classFilter === null)){
      fetchAddress += '?'
    }
    if (satelliteFilter !== null) {
      fetchAddress += `satellite=${satelliteFilter}&`
    }
    if (srcTypeFilter !== null) {
      fetchAddress += `srcType=${srcTypeFilter}&`
    }
    if (classFilter !== null) {
      fetchAddress += `eventClass=${classFilter}&`
    }

    fetch(fetchAddress)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Heatmap data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setHeatmapData(data.data)
        setHeatmapMaxIntensity(data.maxIntensity)
        setShowHeatmap(true)
        setShowSearchResults(false)
        setIsHeatMapLoading(false)
      })
      .catch(error => {
        alert(error);
        setIsHeatMapLoading(false)
      });
  }

  function sortData(data) {
    if (data.length > 0) {
      data.sort((a, b) => {
        if (a._source.value.properties.acq_date < b._source.value.properties.acq_date) {
          return -1;
        }
        if (a._source.value.properties.acq_date > b._source.value.properties.acq_date) {
          return 1;
        }
        return 0;
      });
    }
  }

  function searchData(minLat, maxLat, minLng, maxLng, isAdvanced) {
    setIsSearchLoading(true)
    setResultData([])
    
    let fetchAddress = backendUrl + `/api/v1/oil-spills?minLat=${minLat}&maxLat=${maxLat}&minLng=${minLng}&maxLng=${maxLng}`
    if (isAdvanced){
      if (minArea !== null) fetchAddress += `&minArea=${minArea}`
      if (maxArea !== null) fetchAddress += `&maxArea=${maxArea}`
      if (minVolume !== null) fetchAddress += `&minVolume=${minVolume}`
      if (maxVolume !== null) fetchAddress += `&maxVolume=${maxVolume}`
      if (startingDate !== null) fetchAddress += `&startingDate=${startingDate}`
      if (endingDate !== null) fetchAddress += `&endingDate=${endingDate}`
      if (satellite !== null) fetchAddress += `&satellite=${satellite}`
      if (srcType !== null) fetchAddress += `&srcType=${srcType}`
    }

    fetch(fetchAddress)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 400) {
          throw new Error('Request not valid, please insert valid coordinates');
        } else if (response.status === 404) {
          throw new Error('Spill data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setIsSearchLoading(false)
        if (data.data.length > 0) {
          sortData(data.data)
          setResultData(data.data)
          setAverageArea(data.averageArea)
          setTotalArea(data.totalArea)
          setMenuState("closed")
          setShowHeatmap(false)
          setShowSearchResults(true)
        } else {
          alert("No results found")
        }

      })
      .catch(error => {
        alert(error);
        setIsSearchLoading(false)
      });
  }

  function restoreHeatmap() {
    setShowHeatmap(true)
    setResultData([])
    setShowSearchResults(false)
  }

  function handleSearchReset() {
    setClicks(0);
    setLat1(null);
    setLng1(null);
    setLat2(null);
    setLng2(null);
    setSrcType(null);
    setMinArea(null);
    setMaxArea(null);
    setSatellite(null);
    setSrcType(null);
    setMinVolume(null);
    setMaxVolume(null);
    setStartingDate(null);
    setEndingDate(null);
    restoreHeatmap();
  }

  function handleHeatmapFilterReset() {
    setSatelliteFilter(null);
    setSrcTypeFilter(null);
    setClassFilter(null);
  }
    
  function filterHeatmapData(satelliteFilter, srcTypeFilter, classFilter) {
    fetchData(satelliteFilter, srcTypeFilter, classFilter);
  }


  useEffect(() => {
    fetchData(null, null, null)
  }, []);



  return (
    <>

      {modality === "ocean" && (
        <Menu
          modality={modality}
          menuState={menuState}
          setMenuState={setMenuState}
          restoreHeatmap={restoreHeatmap}
          clicks={clicks}
          setClicks={setClicks}
          lat1={lat1}
          setLat1={setLat1}
          lng1={lng1}
          setLng1={setLng1}
          lat2={lat2}
          setLat2={setLat2}
          lng2={lng2}
          setLng2={setLng2}
          startingDate={startingDate}
          setStartingDate={setStartingDate}
          endingDate={endingDate}
          setEndingDate={setEndingDate}
          minArea={minArea}
          setMinArea={setMinArea}
          maxArea={maxArea}
          setMaxArea={setMaxArea}
          satellite={satellite}
          setSatellite={setSatellite}
          srcType={srcType}
          setSrcType={setSrcType}
          minVolume={minVolume}
          setMinVolume={setMinVolume}
          maxVolume={maxVolume}
          setMaxVolume={setMaxVolume}
          includeSimulations={includeSimulations}
          setIncludeSimulations={setIncludeSimulations}
          searchData={searchData}
          resetData={handleSearchReset}

          satelliteFilter={satelliteFilter}
          setSatelliteFilter={setSatelliteFilter}
          srcTypeFilter={srcTypeFilter}
          setSrcTypeFilter={setSrcTypeFilter}
          classFilter={classFilter}
          setClassFilter={setClassFilter}
          filterHeatmapData={filterHeatmapData}
          handleHeatmapFilterReset={handleHeatmapFilterReset}
        />
      )}

      {modality === "coast" && (
        <Menu
          modality={modality}
          clicks={clicks}
          setClicks={setClicks}
          lat1={lat1}
          setLat1={setLat1}
          lng1={lng1}
          setLng1={setLng1}
          lat2={lat2}
          setLat2={setLat2}
          lng2={lng2}
          setLng2={setLng2}
          startingDate={startingDate}
          setStartingDate={setStartingDate}
          endingDate={endingDate}
          setEndingDate={setEndingDate}
          minBeached={minBeached}
          setMinBeached={setMinBeached}
          maxBeached={maxBeached}
          setMaxBeached={setMaxBeached}
        />
      )}

      <Map
        showHeatmap={showHeatmap}
        showSearchResults={showSearchResults}      
        clicks={clicks}
        setClicks={setClicks}
        lat1={lat1}
        setLat1={setLat1}
        lng1={lng1}
        setLng1={setLng1}
        lat2={lat2}
        setLat2={setLat2}
        lng2={lng2}
        setLng2={setLng2}
        heatmapData={heatmapData}
        resultData={resultData}
        highlightedID={highlightedID}
        setHighlightedID={setHighlightedID}
        focusedID={focusedID}
        setFocusedID={setFocusedID}
        searchData={searchData}
        heatmapMaxIntensity={heatmapMaxIntensity}
        
        satelliteFilter={satelliteFilter}
        srcTypeFilter={srcTypeFilter}
        classFilter={classFilter}
      />

      <Gallery
        isVisible={resultData.length > 0}
        data={resultData}
        averageArea={averageArea}
        totalArea={totalArea}
        highlightedID={highlightedID}
        setHighlightedID={setHighlightedID}
        focusedID={focusedID}
        setFocusedID={setFocusedID}
      />

      { isHeatMapLoading && (
        <>
          <Overlay/>
          <LoadingCircle text={"Loading Heatmap..."}/>
        </>
      ) }
      { isSearchLoading && (
        <>
          <Overlay/>
          <LoadingCircle text={"Searching..."}/>
        </>
      ) }

    </>
  );
}



export default HeatMapPage;