import React, { useState, useEffect } from "react";

import SearchForm from "./../search_form/SearchForm";

import "./catalogSearch.css";





function CatalogSearch({ searchData, resetData, lat1, setLat1, lng1, setLng1, lat2, setLat2, lng2, setLng2, startingDate, setStartingDate, endingDate, setEndingDate, minArea, setMinArea, maxArea, setMaxArea, satellite, setSatellite, srcType, setSrcType, minVolume, setMinVolume, maxVolume, setMaxVolume }) {

  return (
    <>
      <div className="statistics-div">

        <div className="catalog-statistics-head">
          <h1>Catalog Search</h1>
        </div>

        <div className="catalog-statistics-content">
          <SearchForm
            modality={"catalog"}
            searchData={searchData}
            resetData={resetData}
            locationRequired={false}

            lat1={lat1}
            setLat1={setLat1}
            lng1={lng1}
            setLng1={setLng1}
            lat2={lat2}
            setLat2={setLat2}
            lng2={lng2}
            setLng2={setLng2}
            startingDate={startingDate}
            setStartingDate={setStartingDate}
            endingDate={endingDate}
            setEndingDate={setEndingDate}
            minArea={minArea}
            setMinArea={setMinArea}
            maxArea={maxArea}
            setMaxArea={setMaxArea}
            satellite={satellite}
            setSatellite={setSatellite}
            srcType={srcType}
            setSrcType={setSrcType}
            minVolume={minVolume}
            setMinVolume={setMinVolume}
            maxVolume={maxVolume}
            setMaxVolume={setMaxVolume}
          />
        </div>

      </div>
    </>

  );
}



export default CatalogSearch;