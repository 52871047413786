import React, { useState, useEffect } from "react";

import "./catalogStatistics.css";

import { backendUrl } from "../../backendUrl";

const metadataMapping = require("./../../assets/observationsMetadataMappings/observationsMetadataMappings.js");



function CatalogStatistics({ setIsLoading }) {

  const [statistics, setStatistics] = useState(null);

  function getStatistics() {
    setIsLoading(true);
    fetch(backendUrl + `/api/v1/catalog/statistics`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Statistics data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setStatistics(data.data[0])
        setIsLoading(false);
      })
      .catch(error => {
        alert(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getStatistics();
  }, []);



  return (
    <div className="statistics-div">

      <div className="catalog-statistics-head">
        <h1>Catalog Statistics</h1>
      </div>

      <div className="catalog-statistics-content">
        {statistics !== null && (
          <>
            <p><span className="label">Total number of oil spills:</span>{" "}{statistics._source.spill_number}</p>
            <p><span className="label">Total Area:</span>{" "}{parseFloat(statistics._source.total_area).toFixed(3)} km<sup>2</sup></p>
            <p><span className="label">Average Area:</span>{" "}{parseFloat(statistics._source.average_area).toFixed(3)} km<sup>2</sup></p>

            <p><span className="label">Satellites involved:</span>{' '}
              {statistics._source.sensor_mapping.map((item, index, array) => (
                <span key={item.sensor} style={{ fontSize: '14px' }}>
                  {item.sensor} ({item.count})
                  {index < array.length - 1 ? ', ' : ''}
                </span>
              ))}
            </p>

            <p><span className="label">Spill Classes:</span>{' '}
              {statistics._source.event_class_mapping.map((item, index, array) => (
                <span key={item.event_class}>
                  {metadataMapping.fromEventClassToLabel(item.event_class)} ({item.count})
                  {index < array.length - 1 ? ', ' : ''}
                </span>
              ))}
            </p>

            <p><span className="label">Spill Typologies:</span>{' '}
              {statistics._source.source_type_mapping.map((item, index, array) => (
                <span key={item.source_type}>
                  {metadataMapping.fromSrcTypeToLabel(item.source_type)} ({item.count})
                  {index < array.length - 1 ? ', ' : ''}
                </span>
              ))}
            </p>
          </>
        )}
      </div>
      
    </div>
  );
}



export default CatalogStatistics;