// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rectangle-selection-div {
    display: flex;
    flex-direction: row;
}

.rectangle-div {
    border: 1px solid var(--dark_brown);
    border-radius: 2px;
    width: 3em;
    height: 3em;
    background-color: rgba(0, 0, 0, 0.05);
}

.rectangle-div.active {
    background-color: rgba(0, 0, 0, 0.2);
}

.rectangle-div i{
    padding: 7px;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.rectangle-div img{
    width: 30px;
    height: 30px;
}

.rectangle-selection-div p {
    width: 100%;
    max-width: 250px;
    margin-left: 1em;
    font-size: 0.8em;
    color: var(--dark_brown);
    word-wrap: break-word
}`, "",{"version":3,"sources":["webpack://./src/components/search_form/rectangle_selector/rectangleSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mCAAmC;IACnC,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,qCAAqC;AACzC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,wBAAwB;IACxB;AACJ","sourcesContent":[".rectangle-selection-div {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.rectangle-div {\r\n    border: 1px solid var(--dark_brown);\r\n    border-radius: 2px;\r\n    width: 3em;\r\n    height: 3em;\r\n    background-color: rgba(0, 0, 0, 0.05);\r\n}\r\n\r\n.rectangle-div.active {\r\n    background-color: rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.rectangle-div i{\r\n    padding: 7px;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.rectangle-div img{\r\n    width: 30px;\r\n    height: 30px;\r\n}\r\n\r\n.rectangle-selection-div p {\r\n    width: 100%;\r\n    max-width: 250px;\r\n    margin-left: 1em;\r\n    font-size: 0.8em;\r\n    color: var(--dark_brown);\r\n    word-wrap: break-word\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
