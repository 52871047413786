// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-div {
    z-index: 1;
    height: 100%;
    width: 100%;
    flex-shrink: 100;
}

.selection-rectangle {
    stroke: var(--blue);
    fill: var(--light_blue);
}

.heatmap-polygon:hover {
    pointer-events: none;
    stroke: #782727;
    stroke-width: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/heatmap/map.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,eAAe;AACnB","sourcesContent":["@import url(\"./../../assets/colors.css\");\r\n\r\n.map-div {\r\n    z-index: 1;\r\n    height: 100%;\r\n    width: 100%;\r\n    flex-shrink: 100;\r\n}\r\n\r\n.selection-rectangle {\r\n    stroke: var(--blue);\r\n    fill: var(--light_blue);\r\n}\r\n\r\n.heatmap-polygon:hover {\r\n    pointer-events: none;\r\n    stroke: #782727;\r\n    stroke-width: 2;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
