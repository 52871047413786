import React, { useState, useEffect } from "react";

import "./heatFilters.css";
import "./../../assets/form.css"
import "./../../assets/formButtons.css"

import { backendUrl } from "../../backendUrl";

const metadataMapping = require("./../../assets/observationsMetadataMappings/observationsMetadataMappings.js");



function HeatFilters({ satelliteFilter, setSatelliteFilter, srcTypeFilter, setSrcTypeFilter, classFilter, setClassFilter, filterHeatmapData, handleHeatmapFilterReset }) {
  
  const [satellitesMapping, setSatellitesMapping] = useState([]);
  const [srcTypesMapping, setSrcTypesMapping] = useState([]);
  const [classesMapping, setClassesMapping] = useState([]);



  function getSatellitesMapping() {
    fetch(backendUrl + '/api/v1/catalog/satellites-mapping')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Satellites mapping data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setSatellitesMapping(data.satellites_mapping)
      })
      .catch(error => {
        alert(error);
      });
  }

  function getClassesMapping() {
    fetch( backendUrl + '/api/v1/catalog/event-class-mapping')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Event class mapping data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setClassesMapping(data.event_class_mapping)
      })
      .catch(error => {
        alert(error);
      });
  }

  function getSrcTypesMapping() {
    fetch(backendUrl + '/api/v1/catalog/src-type-mapping')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Source type mapping data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setSrcTypesMapping(data.source_type_mapping)
      })
      .catch(error => {
        alert(error);
      });
  }

  function handleFilterReset(event) {
    event.preventDefault();
    handleHeatmapFilterReset();
    filterHeatmapData(null, null, null);
  }

  function handleFilterSubmit(event) {
    event.preventDefault();
    filterHeatmapData(satelliteFilter, srcTypeFilter, classFilter);
  }

  useEffect(() => {
    getSatellitesMapping();
    getSrcTypesMapping();
    getClassesMapping();
  }, []);



  return (
    <>
      <form onSubmit={handleFilterSubmit} onReset={handleFilterReset}>

      <div className="form-group-div">
        <div className="form-field-div">
          <label >SAT.</label>
          <div className="input-div">
            <select
              id="satelliteSelected"
              value={satelliteFilter === null ? '' : satelliteFilter}
              onChange={(event) => setSatelliteFilter(event.target.value)}
            >
              <option value="">any</option>
              {satellitesMapping.map((satellite, index) => {
                return <option key={index} value={satellite.sensor}>{satellite.sensor} ({satellite.count})</option>
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group-div">
        <div className="form-field-div">
          <label >CLASS</label>
          <div className="input-div">
            <select
              id="classSelected"
              value={classFilter === null ? '' : classFilter}
              onChange={(event) => setClassFilter(event.target.value)}
            >
              <option value="">any</option>
              {classesMapping.map((eventClass, index) => {
                return <option key={index} value={eventClass.event_class}>{metadataMapping.fromEventClassToLabel(eventClass.event_class)} ({eventClass.count})</option>
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="form-group-div">
        <div className="form-field-div">
          <label >SRC. TYPE</label>
          <div className="input-div">
            <select
              id="srcTypeSelected"
              value={srcTypeFilter === null ? '' : srcTypeFilter}
              onChange={(event) => setSrcTypeFilter(event.target.value)}
            >
              <option value="">any</option>
              {srcTypesMapping.map((source, index) => {
                return <option key={index} value={source.source_type}>{metadataMapping.fromSrcTypeToLabel(source.source_type)} ({source.count})</option>
              })}
            </select>
          </div>
        </div>
      </div>


      <div className="form-buttons-div">
        <button className="search-button" type="submit">APPLY</button>
        <button className="reset-button" type="reset">RESTORE</button>
      </div>

      </form>
    </>
  );
}

export default HeatFilters;
