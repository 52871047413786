function LocationForm({ lat1, setLat1, lng1, setLng1, lat2, setLat2, lng2, setLng2 }) {
    return (
      <div className="form-group-div">
        <div className="form-field-div">
          <label >TOP-LEFT</label>
          <div className="input-div">
            <input type="number" placeholder="lng" id="lng1" min="-180" max="180" step={0.001} value={lng1 === null ? '' : lng1} onChange={(event) => setLng1(event.target.value)} />
            <input type="number" placeholder="lat" id="lat1" min="-90" max="90" step={0.001} value={lat1 === null ? '' : lat1} onChange={(event) => setLat1(event.target.value)} />
          </div>
        </div>

        <div className="form-field-div">
          <label >TOP-RIGHT</label>
          <div className="input-div">
            <input type="number" placeholder="lng" id="lng2" min="-180" max="180" step={0.001} value={lng2 === null ? '' : lng2} onChange={(event) => setLng2(event.target.value)}/>
            <input type="number" placeholder="lat" id="lat2" min="-90" max="90" step={0.001} value={lat1 === null ? '' : lat1} onChange={(event) => setLat2(event.target.value)} />
          </div>
        </div>

        <div className="form-field-div">
          <label >BOTTOM-LEFT</label>
          <div className="input-div">
            <input type="number" placeholder="lng" id="lng3" min="-180" max="180" step={0.001} value={lng1 === null ? '' : lng1} onChange={(event) => setLng1(event.target.value)}/>
            <input type="number" placeholder="lat" id="lat3" min="-90" max="90" step={0.001} value={lat2 === null ? '' : lat2} onChange={(event) => setLat2(event.target.value)}/>
          </div>
        </div>

        <div className="form-field-div">
          <label >BOTTOM-RIGHT</label>
          <div className="input-div">
            <input type="number" placeholder="lng" id="lng4" min="-180" max="180" step={0.001} value={lng2 === null ? '' : lng2} onChange={(event) => setLng2(event.target.value)} />
            <input type="number" placeholder="lat" id="lat4" min="-90" max="90" step={0.001} value={lat2 === null ? '' : lat2} onChange={(event) => setLat2(event.target.value)} />
          </div>
        </div>
      </div>
    );
  }
  
  export default LocationForm;
  