import React, { useEffect } from 'react';
import './menu.css';

import MenuItem from './MenuItem';
import SearchForm from './../search_form/SearchForm';
import HeatFilters from '../heatmap_filters/HeatFilters';
import DownloadForm from '../download/DownloadForm';
import SpillMetadata from '../metadata/SpillMetadata';
import SimulationMetadata from '../metadata/SimulationMetadata';

import SearchIcon from './../../assets/icons/search_icon.svg';
import FilterIcon from './../../assets/icons/filter_icon.svg';
import BackSearchIcon from './../../assets/icons/back_search_icon.svg';
import MetadataIcon from './../../assets/icons/metadata_icon.svg';
import SimulationMetadataIcon from './../../assets/icons/simulation_metadata_icon.svg';
// import DownloadIcon from './../../assets/icons/download_icon.svg';
import CatalogIcon from './../../assets/icons/catalog_menu_icon.svg';
import CatalogSearchIcon from './../../assets/icons/catalog_search_icon.svg';
import CatalogStatisticsIcon from './../../assets/icons/catalog_stats_icon.svg';
import ModelStatisticsIcon from './../../assets/icons/model_stats_icon.svg';



function Menu({ modality, searchData, ...props }) {

   const menuItems = []

  if (modality === 'ocean') {
    menuItems.push(
      {
        label: "Search",
        icon: SearchIcon,
        content: (
          <>
            <h1>Search on the Ocean</h1>
            <SearchForm modality={modality} searchData={searchData} locationRequired={true} {...props} />
          </>
        ),
        type: "search",
      },
      {
        label: "Filters",
        icon: FilterIcon,
        content: (
          <>
            <h1>Heatmap Filters</h1>
            <HeatFilters
              satelliteFilter={props.satelliteFilter}
              setSatelliteFilter={props.setSatelliteFilter}
              srcTypeFilter={props.srcTypeFilter}
              setSrcTypeFilter={props.setSrcTypeFilter}
              classFilter={props.classFilter}
              setClassFilter={props.setClassFilter}
              filterHeatmapData={props.filterHeatmapData}
              handleHeatmapFilterReset={props.handleHeatmapFilterReset}
            />
          </>
        ),
        type: "filters",
      },
    )
  // } else if (modality === 'coast') {
  //   menuItems.push(
  //     {
  //       label: "Search",
  //       icon: SearchIcon,
  //       content: (
  //         <>
  //           <h1>Search on the Coast</h1>
  //           <SearchForm modality={modality}  searchData={searchData} {...props} />
  //         </>
  //       ),
  //       type: "search",
  //     },
  //     {
  //       label: "Filters",
  //       icon: FilterIcon,
  //       content: (
  //         <>
  //           <h1>Heatmap Filters</h1>
  //           <HeatFilters />
  //         </>
  //       ),
  //       type: "filters",
  //     },
  //   )
  } else if (modality === 'catalog') {
    menuItems.push(
      {
        label: "Catalog",
        icon: CatalogIcon,
        content: (
          <>
          </>
        ),
        action: () => props.handleSelectCatalog(),
        type: "catalog",
      },
      {
        label: "Catalog Search",
        icon: CatalogSearchIcon,
        content: (
          <>
          </>
        ),
        action: () => props.handleSelectCatalogSearch(),
        type: "catalog_search",
      },
      {
        label: "Catalog Statistics",
        icon: CatalogStatisticsIcon,
        content: (
          <>
          </>
        ),
        action: () => props.handleSelectCatalogStatistics(),
        type: "catalog_statistics",
      },
      {
        label: "Model Statistics",
        icon: ModelStatisticsIcon,
        content: (
          <>
          </>
        ),
        action: () => props.handleSelectModelStatistics(),
        type: "model_statistics",
      }
    )
  } else if (modality === 'analysis') {
    menuItems.push(
      {
        label: "Back to Search",
        icon: BackSearchIcon,
        content: (
          <>
          </>
        ),
        action: () => {
          props.removeId();
          props.setMenuState("closed");
        },
        type: "back_search",
      },
      {
        label: "Spill Metadata",
        icon: MetadataIcon,
        content: (
          <>
            <h1>Spill Metadata</h1>
            <SpillMetadata {...props} />
          </>
        ),
        type: "metadata",
      },
      {
        label: "Simulation Metadata",
        icon: SimulationMetadataIcon,
        content: (
          <>
            <h1>Simulation Metadata</h1>
            <SimulationMetadata {...props} />
          </>
        ),
        type: "simulation_metadata",
      },
      // {
      //   label: "Download Oil Spill Data",
      //   icon: DownloadIcon,
      //   content: 
      //   (
      //     <>
      //       <h1>Download</h1>
      //       <DownloadForm handleDownloadRequest={props.handleDownloadRequest}/>
      //     </>
      //   ),
      //   type: "download",
      // },
    )
  } 

  const { lat1, lng1, lat2, lng2, setLat1, setLat2, setLng1, setLng2 } = props;
  useEffect(() => {
    if (lat1 && lng1 && lat2 && lng2) {
      let newLat1 = Math.max(lat1, lat2);
      let newLat2 = Math.min(lat1, lat2);
      let newLng1 = Math.min(lng1, lng2);
      let newLng2 = Math.max(lng1, lng2);
  
      setLat1(newLat1);
      setLat2(newLat2);
      setLng1(newLng1);
      setLng2(newLng2);
    }
  }, [lat1, lng1, lat2, lng2, setLat1, setLat2, setLng1, setLng2]);

  function handleToggleOpen(type) {
    props.setMenuState(type || "closed");
  }



  return (
    <>
      <div className="menu-icons-div">
        {menuItems.map((item) => (
          <MenuItem
            key={item.type}
            label={item.type}
            icon={item.icon}
            action={item.action}
            isOpen={item.type === props.menuState}
            handleOpen={() => handleToggleOpen(item.type)}
            handleClose={() => handleToggleOpen("closed")}
          />
        ))}
      </div>

      <div className={`menu-content-div ${props.menuState === "closed" ? 'closed' : ''}`}>
        {menuItems.find((item) => item.type === props.menuState)?.content}
      </div>

    </>
  );

}



export default Menu;