// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
    text-decoration: none;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 2px solid var(--dark_blue);
    padding: 2%;
}

.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

.nav-link.active {
    background-color: rgba(255, 255, 255, 0.5);
}

.nav-link div {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-link img {
    width: 40%;
    padding: 2%;
}

.nav-link h1 {
    text-align: center;
    color: white;
    font-size: 12px;
}

@media screen and (max-width: 1023px) {
    .nav-link {
        height: 100%;
        border: 0;
        border-right: 2px solid var(--dark_blue);
    }

    .nav-link img {
        height: 80%;
        width: 80%;
    }    

    .nav-link h1 {
        font-size: 10px;
    }
}

@media screen and (max-width: 767px) {
    .nav-link h1 {
        display: none;
        font-size: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/navBarItem.css"],"names":[],"mappings":"AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,yCAAyC;IACzC,WAAW;AACf;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;QACZ,SAAS;QACT,wCAAwC;IAC5C;;IAEA;QACI,WAAW;QACX,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ","sourcesContent":["@import url(\"./../../assets/colors.css\");\r\n\r\n.nav-link {\r\n    text-decoration: none;\r\n    width: 100%;\r\n    height: fit-content;\r\n    background-color: var(--blue);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-bottom: 0;\r\n    border-bottom: 2px solid var(--dark_blue);\r\n    padding: 2%;\r\n}\r\n\r\n.nav-link:hover {\r\n    background-color: rgba(255, 255, 255, 0.25);\r\n}\r\n\r\n.nav-link.active {\r\n    background-color: rgba(255, 255, 255, 0.5);\r\n}\r\n\r\n.nav-link div {\r\n    width: fit-content;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.nav-link img {\r\n    width: 40%;\r\n    padding: 2%;\r\n}\r\n\r\n.nav-link h1 {\r\n    text-align: center;\r\n    color: white;\r\n    font-size: 12px;\r\n}\r\n\r\n@media screen and (max-width: 1023px) {\r\n    .nav-link {\r\n        height: 100%;\r\n        border: 0;\r\n        border-right: 2px solid var(--dark_blue);\r\n    }\r\n\r\n    .nav-link img {\r\n        height: 80%;\r\n        width: 80%;\r\n    }    \r\n\r\n    .nav-link h1 {\r\n        font-size: 10px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 767px) {\r\n    .nav-link h1 {\r\n        display: none;\r\n        font-size: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
