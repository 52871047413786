import React from "react";
import {  Polygon, Tooltip } from 'react-leaflet';



function HeatmapCell({ lon, lat, counter, maxCounter, setLat1, setLng1, setLat2, setLng2, searchData }) {
  
  const precision = 0.5

  function getColor(min, max, intensity) {
    const gradientColors = ["ffba08", "faa307", "f48c06", "e85d04", "dc2f02", "d00000", "9d0208", "6a040f"]
    const gradientStep = (max - min) / gradientColors.length;
    const gradientIndex = Math.ceil(intensity / gradientStep);
    const gradientColor = gradientColors[gradientIndex-1];
    return `#${gradientColor}`;
  }

  return (
    <>
      <Polygon
        className="heatmap-polygon"
        positions={[
          [lat, lon],
          [lat + precision, lon],
          [lat + precision, lon + precision],
          [lat, lon + precision]
        ]}
        weight={0}
        smoothFactor={0.1}
        fillOpacity={0.7}
        fillColor={getColor(0, maxCounter, counter)}
        color={getColor(0, maxCounter, counter)}
        eventHandlers={{
          click: () => {
            setLat1(lat);
            setLng1(lon);
            setLat2(lat + precision);
            setLng2(lon + precision);
            searchData(lat, lat + precision, lon, lon + precision, false);
          }
        }}
      >
        <Tooltip>{counter} spills</Tooltip>
      </Polygon>
    </>
  );
}



export default HeatmapCell;