const event_class_mapping = {
  "A": "Mineral oil",
  "B": "Most likely mineral oil",
  "C": "Less likely mineral oil"
};

const src_typ_mapping = {
  1: "Unknown",
  2: "Oil Platform",
  3: "Ship",
  4: "Shipwreck",
  5: "Fish Farm",
  6: "Oil Seep",
  7: "Pipeline",
  8: "Refinery"
}

function fromEventClassToLabel(class_label) {
  return event_class_mapping[class_label];
}

function fromSrcTypeToLabel(src_typ) {
  return src_typ_mapping[src_typ];
}

module.exports = {
  fromEventClassToLabel,
  fromSrcTypeToLabel
};