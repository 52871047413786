import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import NavBar from "../components/navigation/NavBar";
import HeatMapPage from "../pages/heatmap/HeatMapPage";
import CatalogPage from "../pages/catalog/CatalogPage";
import AnalysisPage from "../pages/analysis/AnalysisPage";

import "./main.css";



function Main() {

  return (
    <div className="main-div">

      <Router>

        <div className="navigation-div">
          <NavBar />
        </div> 

        <div className="content-div">
          <Routes>
            <Route path="/" element={ <Navigate to="/ocean" /> } />
            <Route path="/ocean" element={ <HeatMapPage modality={"ocean"} /> } />
            {/* <Route path="/coast" element={ <div className="not-available-section-div"> <h1>This section is not available yet</h1> </div> } /> */}
            <Route path="/catalog" element={ <CatalogPage /> } />
            <Route path="/analysis" element={ <AnalysisPage /> } />
          </Routes>
        </div>
        
      </Router>

    </div>
    )
}

export default Main;