// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-outer-div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-div {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    padding: 1em;
    border: 1px solid rgb(180, 180, 180);
}

.loading-div h1 {
    margin: 0.5em 0;
    font-size: 1.2em;
    color: rgb(180, 180, 180);
}`, "",{"version":3,"sources":["webpack://./src/components/loading/loadingCircle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".loading-outer-div {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.loading-div {\r\n    z-index: 100;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: white;\r\n    border-radius: 4px;\r\n    padding: 1em;\r\n    border: 1px solid rgb(180, 180, 180);\r\n}\r\n\r\n.loading-div h1 {\r\n    margin: 0.5em 0;\r\n    font-size: 1.2em;\r\n    color: rgb(180, 180, 180);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
