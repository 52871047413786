import React, { useState, useEffect, useRef, memo } from "react";
import { useNavigate} from 'react-router-dom';

import Minimap from "../minimap/Minimap";

import "./galleryItem.css";
import "leaflet/dist/leaflet.css";



const GalleryItem = memo(function GalleryItem({ id, identifier, acqDate, acqTime, area, type, coordinates, center, isHighlighted, setHighlightedID, setFocusedID }) {

  const itemRef = useRef(null);
  const [skipScroll, setSkipScroll] = useState(false);

  const navigate = useNavigate();
  
  function handleAnalyzeClick(){
    navigate(`/analysis`, {state:{id:identifier}});
  }

  function handleFocusClick(){
    setHighlightedID(id);
    setFocusedID(id);
  }

  function handleItemClick(event) {
    if (event.target.tagName === 'BUTTON') {
      return;
    }
    if (isHighlighted) {
      setHighlightedID(null);
    } else {
      setSkipScroll(true);
      setHighlightedID(id);
      setFocusedID(null)
    }
  }
  

  useEffect(() => {
    if (isHighlighted && itemRef.current) {
      if (!skipScroll) {
        itemRef.current.scrollIntoView({ alignToTop: false,  behavior: "smooth" });
      } else {
        setSkipScroll(false);
      }
    }
  }, [isHighlighted]);


  const date = new Date(acqDate);
  const dateOptions = { timeZoneName: 'short' };
  const finalDate = `${date.toLocaleDateString('en-US', dateOptions)}`;

  return (
    <div
      className={`item-div ${isHighlighted ? "active" : ""}`}
      onClick={handleItemClick}
      ref={itemRef}
    >
      <div className="item-container">
          
        <div className="shape-div">
          <Minimap type={type} coordinates={coordinates} center={center} />
        </div>

        <div className="item-content-div">
          <div className="text-div">
            <p>{identifier}</p>
            <p>{parseFloat(area).toFixed(3)} km&sup2;</p>
            <p>{finalDate}</p>
            <p>{acqTime}</p>
          </div>
          {isHighlighted && (
            <div className="item-buttons-div">
              <button className="focus-button" onClick={handleFocusClick}>Focus</button>
              <button className="analyze-button" onClick={handleAnalyzeClick}>Inspect</button>
            </div>
          )}
        </div>
        
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  return (prevProps.isHighlighted === nextProps.isHighlighted);
});



export default GalleryItem;