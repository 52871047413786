import React from "react";

import "./metadata.css";



function SimulationMetadata({ ...props }) {
  return (
    <>
      {props.sim_metadata_not_available !== null && props.sim_metadata_not_available !== undefined && props.sim_metadata_not_available === true && (
        <div className="spill-metadata-fields-group">
          <p style={{color: "red"}}>If no simulation frame is selected, <br/>no metadata is available.</p>
        </div>
      )}

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>OIL CENTER LNG.</p>
          <div>
            {props.oil_center_lon !== null && props.oil_center_lon !== undefined
              ? (<p>{parseFloat(props.oil_center_lon).toFixed(3)}</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>OIL CENTER LAT.</p>
          <div>
            {props.oil_center_lat !== null && props.oil_center_lat !== undefined
              ? (<p>{parseFloat(props.oil_center_lat).toFixed(3)}</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>TOTAL OIL</p>
          <div>
            {props.oil_total !== null && props.oil_total !== undefined
              ? (<p>{parseFloat(props.oil_total).toFixed(3)} km&sup2;</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>BEACHED OIL CENTER LNG.</p>
          <div>
            {props.beached_center_lon !== null && props.beached_center_lon !== undefined
              ? (<p>{parseFloat(props.beached_center_lon).toFixed(3)}</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>BEACHED OIL CENTER LAT.</p>
          <div>
            {props.beached_center_lat !== null && props.beached_center_lat !== undefined
              ? (<p>{parseFloat(props.beached_center_lat).toFixed(3)}</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>TOTAL BEACHED OIL</p>
          <div>
            {props.beached_total !== null && props.beached_total !== undefined
              ? (<p>{parseFloat(props.beached_total).toFixed(3)} km&sup2;</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p style={{ fontSize: "1.1em" }}>SKILL SCORE</p>
          <div>
            {props.skill_score !== null && props.skill_score !== undefined
              ? (<p style={{ fontSize: "1.1em" }}>{parseFloat(props.beached_total).toFixed(3)}</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
      </div>


    </>
  );
}

export default SimulationMetadata;