import RectangleIcon from './../../../assets/icons/rectangle_icon.svg';
import './rectangleSelector.css';

function RectangleSelector({ clicks, handleRectangleModeClick }) {
    return (
      <>
        <div className="rectangle-selection-div">
          <div className={`rectangle-div ${clicks > 0 ? 'active' : ''}`}>
            <i onClick={handleRectangleModeClick}>
              <img src={RectangleIcon} alt='rectangle icon' />
            </i>
          </div>
            <p>Click the rectangle icon to pick directly on the map two extreme points</p>
        </div>
      </>
    );
  }
  
  export default RectangleSelector;
  