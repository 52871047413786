import React, { useState, useEffect } from 'react';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './slider.css';

function Slider ({ value, setValue, deltaTime }) {
  
  const [isPlaying, setIsPlaying] = useState(false);
  const velocity = 200

  useEffect(() => {
		let intervalId = null;

		if (isPlaying) {
			intervalId = setInterval(() => {
				setValue((prevValue) => {
					if (prevValue < 72) {
						return prevValue + 1;
					} else {
						clearInterval(intervalId);
						setIsPlaying(false);
						return prevValue;
					}
				});
			}, velocity);

			return () => clearInterval(intervalId);
		}
  }, [isPlaying, setValue]);

	function handlePrevFrame() {
		if (value > 0) {
			setValue((prevValue) => prevValue - 1);
		}
	}

	function handleNextFrame() {
		if (value < 72) {
			setValue((prevValue) => prevValue + 1);
		}
	}

	const handlePlayButtonClick = () => {
		setIsPlaying(!isPlaying);
		if (value === 72) {
			setValue(0);
		}
	};

	let playIconClassName = "bi bi-play-circle-fill"
	let sliderClassName = "slider"
	if (isPlaying) {
		playIconClassName = "bi bi-pause-circle-fill"
		sliderClassName = "slider active"
	}

	function formatHours(hours) {
		const days = Math.floor(hours / 24);
		const remainingHours = hours % 24;
	  
		if (days > 0) {
		  	return `+ ${days}d ${remainingHours}h`;
		} else {
		  	return `+ ${remainingHours}h`;
		}
	}

  function jumpToDeltaTime() {
    if (deltaTime) {
      setValue(deltaTime)
    }
  }

	return (
		<div className='controls-div'>

			<div className="time-div">
				<p>{formatHours(value)}</p>
			</div>
				
			<div className='slider-div'>
				<input
					type="range"
					min="0"
					max="72"
					value={value}
					onChange={(changeEvent) => setValue(parseInt(changeEvent.target.value))}
					className={sliderClassName}
					id="myRange"
				/>
			</div>

			<div className='buttons-div'>
				<span>
					<i className="bi bi-arrow-left-circle-fill" onClick={handlePrevFrame}></i>
				</span>
				<span>
					<i className={playIconClassName} onClick={handlePlayButtonClick}></i>
				</span>
				<span>
					<i className="bi bi-arrow-right-circle-fill" onClick={handleNextFrame}></i>
				</span>
        {deltaTime && deltaTime <= 72 && (
          <span>
            <i className="bi bi-skip-end-circle" title={`Jump to the Next Event Timestamp (+${deltaTime} hours)`} onClick={jumpToDeltaTime}></i>
          </span>
        )}
        {deltaTime && deltaTime > 72 && (
          <span
            style={{
              opacity: 0.5,
              cursor: "not-allowed"
            }}
          >
            <i className="bi bi-skip-end-circle" title={`Jump to the Next Event not available`}></i>
          </span>
        )}
			</div>
				
		</div>
	);
};

export default Slider;
