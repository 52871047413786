import React, { useEffect, useRef } from "react";

import TableHead from "./table_components/TableHead";
import TableBody from "./table_components/TableBody";
import PageController from "./page_controller/PageController";

import "./table.css";



function Table({ catalogData, catalogLength, page, setPage, rowsToShow, accessorSorted, setAccessorSorted, order, setOrder, handleSortingChange, filterLabel }) {

  const columns = [
    { label: "Spill", accessor: "spill", sortable: false },
    { label: "ID", accessor: "identifier", sortable: false },
    { label: "Acq. Date", accessor: "acq_date", sortable: true },
    { label: "Acq. Time", accessor: "acq_time", sortable: false },
    { label: "Area (km²)", accessor: "area", sortable: true },
    { label: "Min. Vol. (t/m²)", accessor: "min_volume", sortable: true },
    { label: "Max. Vol. (t/m²)", accessor: "max_volume", sortable: true },
  ];

  const divRef = useRef(null);

  function scrollToTop() {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }



  return (
    <>
      {catalogData.length > 0 && (
        <>
          <div
            className="table-container-div"
            ref={divRef}
          >
            <table>
              <TableHead
                columns={columns}
                handleSortingChange={handleSortingChange}
                accessorSorted={accessorSorted}
                order={order}
              />
              <TableBody
                columns={columns}
                tableData={catalogData}
                page={page}
                rowsToShow={rowsToShow}
              />
            </table>
          </div>

          <div className="page-controller-div">
            <PageController
              page={page}
              setPage={setPage}
              rowsToShow={rowsToShow}
              catalogLength={catalogLength}
              scrollToTop={scrollToTop}
            /> 
          </div>

          <div className="filter-label-div">
            <p>{filterLabel}</p>
          </div>

        </>
      )}
    </>

  );
}



export default Table;