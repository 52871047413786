// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-map {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: auto;
}

.analysis-polygon {
    stroke: var(--blue);
    fill: var(--light_blue);
}

.analysis-polygon:hover {
    stroke: var(--dark_blue);
    fill: var(--dark_blue);
}

.shadow-polygon {
    stroke: black;
    fill: black;
    opacity: 0.1;
}

.shadow-polygon:hover {
    opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/components/analysis/analysisMap.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".analysis-map {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    z-index: 1;\r\n    pointer-events: auto;\r\n}\r\n\r\n.analysis-polygon {\r\n    stroke: var(--blue);\r\n    fill: var(--light_blue);\r\n}\r\n\r\n.analysis-polygon:hover {\r\n    stroke: var(--dark_blue);\r\n    fill: var(--dark_blue);\r\n}\r\n\r\n.shadow-polygon {\r\n    stroke: black;\r\n    fill: black;\r\n    opacity: 0.1;\r\n}\r\n\r\n.shadow-polygon:hover {\r\n    opacity: 0.3;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
