// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spill-polygon {
    stroke: var(--blue);
    fill: var(--light_blue);
}`, "",{"version":3,"sources":["webpack://./src/components/minimap/minimap.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".spill-polygon {\r\n    stroke: var(--blue);\r\n    fill: var(--light_blue);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
