import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';


function ContextMenu({ focusOnObservation }) {

  const MENU_ID = 'context-menu';

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event){
      show({
        event,
        props: {
          key: 'value'
        }
      })
  }

  return (
    <>
      <div style={{width: "100%", height:"100%"}} onContextMenu={handleContextMenu} />
      <Menu id={MENU_ID} >
        <Item onClick={focusOnObservation} >Focus on the observation</Item>
      </Menu>
    </>
  );
}

export default ContextMenu;