// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --blue_bg: rgba(69, 181, 196, 0.4);
  --light_brown_bg: rgba(246, 247, 243, 0.7);

  --light_blue: rgb(96, 212, 217);
  --blue: rgb(69, 181, 196);
  --blue2: rgb(40, 164, 181);
  --dark_blue: rgb(24, 141, 157);
  --darker_blue: rgb(0, 78, 87);
  --darker_blue2: rgb(0, 47, 52);

  --lighter_brown: rgb(253, 253, 252);
  --light_brown: rgb(246, 247, 243);
  --brown: rgb(228, 228, 227);
  --brown2: rgb(215, 215, 198);
  --dark_brown: rgb(174, 174, 164);
  --dark_brown2:rgb(145, 145, 131);
  --darker_brown: rgb(130, 130, 116);
  --darker_brown2: rgb(81, 81, 78);

  --purple: rgb(128, 0, 128);
  --light_purple: rgb(180, 0, 180);
}`, "",{"version":3,"sources":["webpack://./src/assets/colors.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,0CAA0C;;EAE1C,+BAA+B;EAC/B,yBAAyB;EACzB,0BAA0B;EAC1B,8BAA8B;EAC9B,6BAA6B;EAC7B,8BAA8B;;EAE9B,mCAAmC;EACnC,iCAAiC;EACjC,2BAA2B;EAC3B,4BAA4B;EAC5B,gCAAgC;EAChC,gCAAgC;EAChC,kCAAkC;EAClC,gCAAgC;;EAEhC,0BAA0B;EAC1B,gCAAgC;AAClC","sourcesContent":[":root {\r\n  --blue_bg: rgba(69, 181, 196, 0.4);\r\n  --light_brown_bg: rgba(246, 247, 243, 0.7);\r\n\r\n  --light_blue: rgb(96, 212, 217);\r\n  --blue: rgb(69, 181, 196);\r\n  --blue2: rgb(40, 164, 181);\r\n  --dark_blue: rgb(24, 141, 157);\r\n  --darker_blue: rgb(0, 78, 87);\r\n  --darker_blue2: rgb(0, 47, 52);\r\n\r\n  --lighter_brown: rgb(253, 253, 252);\r\n  --light_brown: rgb(246, 247, 243);\r\n  --brown: rgb(228, 228, 227);\r\n  --brown2: rgb(215, 215, 198);\r\n  --dark_brown: rgb(174, 174, 164);\r\n  --dark_brown2:rgb(145, 145, 131);\r\n  --darker_brown: rgb(130, 130, 116);\r\n  --darker_brown2: rgb(81, 81, 78);\r\n\r\n  --purple: rgb(128, 0, 128);\r\n  --light_purple: rgb(180, 0, 180);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
