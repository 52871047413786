import React, { useState } from "react";
import { useNavigate} from 'react-router-dom';

import TableRow from "./TableRow";



function TableBody ({ tableData, columns, page, rowsToShow }) {

  const navigate = useNavigate();
  const [highlightedId, setHighlightedId] = useState(null);

  function handleAnalyzeClick(id){
    navigate(`/analysis`, {state:{id:id}});
  }

  function handleClick(id){
    if (id === highlightedId) {
      setHighlightedId(null);
    } else {
      setHighlightedId(id);
    }
  }

  return (

    <tbody>
      {tableData.map((data) => {
        return (
          <tr
            key={data._id}
            className={data._id === highlightedId ? "highlighted" : "a"}
            onClick={() => handleClick(data._id)}
          >
          {columns.map(({ accessor }) => (
            <TableRow key={accessor} data={data} accessor={accessor} isHighlighted={data._id === highlightedId}/>
          ))}
            <td>
              <button
                className="analyze-button" 
                onClick={() => handleAnalyzeClick(data._source.identifier)}
              >
                Inspect
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};


export default TableBody;