// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-form-div {
    padding-top: 1em;
}

.download-form-div div {
    padding: 0.6em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.download-form-div div p {
    color: var(--darker_brown);
}

.or {
    color: var(--blue);
    font-size: 1em;
    padding: 1em 0;

}

.download-form-div div button {
    padding: 0.3em;
    border-radius: 0.1em;
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--darker_brown);
    border: 1px solid var(--darker_brown);
}

.download-form-div button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.download-form-div button:active {
    background-color: rgba(0, 0, 0, 0.15);
}`, "",{"version":3,"sources":["webpack://./src/components/download/downloadForm.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,2CAA2C;AAC/C;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,cAAc;;AAElB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,qCAAqC;IACrC,0BAA0B;IAC1B,qCAAqC;AACzC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":["@import url(\"./../../assets/colors.css\");\r\n\r\n.download-form-div {\r\n    padding-top: 1em;\r\n}\r\n\r\n.download-form-div div {\r\n    padding: 0.6em 0;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    border-bottom: 1px solid rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.download-form-div div p {\r\n    color: var(--darker_brown);\r\n}\r\n\r\n.or {\r\n    color: var(--blue);\r\n    font-size: 1em;\r\n    padding: 1em 0;\r\n\r\n}\r\n\r\n.download-form-div div button {\r\n    padding: 0.3em;\r\n    border-radius: 0.1em;\r\n    background-color: rgba(0, 0, 0, 0.05);\r\n    color: var(--darker_brown);\r\n    border: 1px solid var(--darker_brown);\r\n}\r\n\r\n.download-form-div button:hover {\r\n    background-color: rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.download-form-div button:active {\r\n    background-color: rgba(0, 0, 0, 0.15);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
