import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import "./loadingCircle.css"

function LoadingCircle({ text }) {
  return (
    <div className="loading-outer-div">
      <div className="loading-div">
        <h1>{text}</h1>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>
    </div>
  );
}

export default LoadingCircle;