// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../assets/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spill-metadata-fields-group {
    margin: 1em 0;
}

.spill-metadata-field {
    margin: 0.2em 0;
    padding: 0.3em 0.3em;
    background-color: var(--brown);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.spill-metadata-field p {
    color: var(--dark_brown);
    font-size: 0.8em;
}

.spill-metadata-field div {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.spill-metadata-field div p {
    color: var(--darker_brown);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0 0.7em;
    border-radius: 0.2em;
    font-size: 0.8em;
}

.spill-metadata-field div p.missing {
    background-color: transparent;
    font-size: 0.8em;
}

.author-image {
    width: 2em;
    margin-left: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/metadata/metadata.css"],"names":[],"mappings":"AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;AAEvB;;AAEA;IACI,0BAA0B;IAC1B,0CAA0C;IAC1C,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":["@import url(\"./../../assets/colors.css\");\r\n\r\n.spill-metadata-fields-group {\r\n    margin: 1em 0;\r\n}\r\n\r\n.spill-metadata-field {\r\n    margin: 0.2em 0;\r\n    padding: 0.3em 0.3em;\r\n    background-color: var(--brown);\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.spill-metadata-field p {\r\n    color: var(--dark_brown);\r\n    font-size: 0.8em;\r\n}\r\n\r\n.spill-metadata-field div {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n\r\n}\r\n\r\n.spill-metadata-field div p {\r\n    color: var(--darker_brown);\r\n    background-color: rgba(255, 255, 255, 0.5);\r\n    padding: 0 0.7em;\r\n    border-radius: 0.2em;\r\n    font-size: 0.8em;\r\n}\r\n\r\n.spill-metadata-field div p.missing {\r\n    background-color: transparent;\r\n    font-size: 0.8em;\r\n}\r\n\r\n.author-image {\r\n    width: 2em;\r\n    margin-left: 0.5em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
