import React, { useState, useEffect } from "react";

import { backendUrl } from "../../backendUrl";

import "./modelStatistics.css";



function ModelStatistics({ setIsLoading }) {

  const [statistics, setStatistics] = useState(null);

  function getStatistics() {
    setIsLoading(true);
    fetch(backendUrl + `/api/v1/catalog/model-statistics`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          throw new Error('Statistics data not found');
        } else {
          throw new Error('Internal Error');
        }
      })
      .then(data => {
        setStatistics(data.data[0])
        setIsLoading(false);
      })
      .catch(error => {
        alert(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getStatistics();
  }, []);



  return (
    <div className="statistics-div">

      <div className="catalog-statistics-head">
        <h1>Model Statistics and Benchmark</h1>
      </div>

      <div className="catalog-statistics-content">
        {statistics !== null && (
          <>
            <p><span className="label">Total number of simulations:</span>{" "}{statistics._source.simulations_number}</p>
            <p><span className="label">Total number of spills with skill score:</span>{" "}{statistics._source.spills_with_skill_score}</p>
            <p><span className="label">Average Skill Score:</span>{" "}{parseFloat(statistics._source.average_skill_score).toFixed(3)}</p>
          </>
        )}
      </div>
      
    </div>
  );
}



export default ModelStatistics;