import React, { memo } from "react";
import Minimap from "../../minimap/Minimap";



const TableRow = memo(function TableRow ({ data, accessor, isHighlighted }) {
  
  let tData;

  if (accessor === "identifier") {
    tData = data._source.identifier;
  } else {
    tData = data._source.value.properties[accessor]
      ? data._source.value.properties[accessor]
      : "——";

    if (accessor === "acq_date") {
      const date = new Date(tData);
      const dateOptions = { timeZoneName: 'short' };
      tData = date.toLocaleDateString('en-US', dateOptions);
    }

    if (accessor === "area" || accessor === "min_volume" || accessor === "max_volume") {
      tData = parseFloat(tData).toFixed(3);
    }
  }

  if (accessor === "spill") {
    return (
      <td key={accessor}>
        <div className="spill-polygon-div">
          <Minimap
            type={data._source.value.geometry.type}
            coordinates={data._source.value.geometry.coordinates}
            center={data._source.value.properties.center}
          />
        </div>
      </td>
    );
  } else {
    return <td key={accessor}>{tData}</td>;
  }
      
}, (prevProps, nextProps) => {
  return (prevProps.isHighlighted === nextProps.isHighlighted);
});



export default TableRow;