import React from "react";

import "./metadata.css";

const metadataMapping = require("./../../assets/observationsMetadataMappings/observationsMetadataMappings.js");



function SpillMetadata({ ...props }) {

  const date = new Date(props.acqDate);
  const dateOptions = { timeZoneName: 'short' };
  const finalDate = `${date.toLocaleDateString('en-US', dateOptions)}`;

  return (
    <>
      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>IDENTIFIER</p>
          <div>
            <p className={props.identifier ? '' : 'missing'}>{props.identifier || 'missing'}</p>
          </div>
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>AUTHOR</p>
          <div>
            <p className={props.identifier ? '' : 'missing'}>{props.author || 'missing'}</p>
            <img src={props.imageUrl} alt="author" className="author-image" />
          </div>
          
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>ACQ. DATE</p>
          <div>
            <p className={props.acqDate ? '' : 'missing'}>{finalDate || 'missing'}</p>
          </div>
        </div>

        <div className="spill-metadata-field">
          <p>ACQ. TIME</p>
            <div>
              <p className={props.acqTime ? '' : 'missing'}>{props.acqTime || 'missing'}</p>
            </div>
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>CENTER LNG.</p>
          <div>
            <p className={props.lngCenter ? '' : 'missing'}>{parseFloat(props.lngCenter).toFixed(3) || 'missing'}</p>
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>CENTER LAT.</p>
          <div>
            <p className={props.latCenter ? '' : 'missing'}>{parseFloat(props.latCenter).toFixed(3) || 'missing'}</p>
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>AREA</p>
          <div>
            {props.area
              ? (<p className={props.area}>{parseFloat(props.area).toFixed(3)} km&sup2;</p>)
              : (<p className='missing'>missing</p>)
            }
          </div>
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>MIN. VOLUME</p>
          <div>
          <p className={props.minVolume ? '' : 'missing'}>{parseFloat(props.minVolume).toFixed(3) || 'missing'}</p>
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>MAX. VOLUME</p>
          <div>
          <p className={props.maxVolume ? '' : 'missing'}>{parseFloat(props.maxVolume).toFixed(3) || 'missing'}</p>
          </div>
        </div>
      </div>

      <div className="spill-metadata-fields-group">
        <div className="spill-metadata-field">
          <p>CLASS</p>
          <div>
          <p className={props.class ? '' : 'missing'}>{metadataMapping.fromEventClassToLabel(props.class) || 'missing'}</p>
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>SATELLITE</p>
          <div>
          <p className={props.satellite ? '' : 'missing'}>{props.satellite || 'missing'}</p>
          </div>
        </div>
        <div className="spill-metadata-field">
          <p>SRC. TYPE</p>
          <div>
          <p className={props.srcType ? '' : 'missing'}>{metadataMapping.fromSrcTypeToLabel(props.srcType) || 'missing'}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpillMetadata;