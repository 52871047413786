import NavBarItem from "./NavBarItem";

import ocean_icon_path from "./../../assets/icons/ocean_icon.svg";
import coast_icon_path from "./../../assets/icons/coast_icon.svg";
import catalog_icon_path from "./../../assets/icons/catalog_icon.svg";
import analysis_icon_path from "./../../assets/icons/analysis_icon.svg";

function NavBar() {

    return (
      <ul>
        <li>
          <NavBarItem text={"Oil in the Sea"} icon_path={ocean_icon_path} link_path={"/ocean"}/>
        </li>
        {/* <li>
          <NavBarItem text={"Oil on the Coast"} icon_path={coast_icon_path} link_path={"/coast"}/>
        </li> */}
        <li>
          <NavBarItem text={"Oil Spills Catalog"} icon_path={catalog_icon_path} link_path={"/catalog"}/>
        </li>
        <li>
          <NavBarItem text={"Oil Spill Analysis"} icon_path={analysis_icon_path} link_path={"/analysis"}/>
        </li>
      </ul>
    )
}

export default NavBar;