import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import L from 'leaflet';

import "./minimap.css"
import "leaflet/dist/leaflet.css";

const mapURL = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"



function MyComponent({ mapRef, type, cords }) {
  
  useEffect(() => {
    if (type === "Polygon") {
      mapRef.current.fitBounds(L.latLngBounds(cords.map(point => [point[1], point[0]])));
    } else if (type === "MultiPolygon") {
      mapRef.current.fitBounds(L.latLngBounds(cords.map(polygon => polygon.map(point => [point[1], point[0]]))));
    }
  }, [cords, mapRef, type]);

  return null;
}



function Minimap({ type, coordinates, center }) {

  const mapRef = useRef(null);
  let positions;

  if (type === "Polygon") {
    positions = coordinates.map(point => [point[1], point[0]]);
  } else if (type === "MultiPolygon") {
    positions = coordinates.map(polygon => polygon.map(point => [point[1], point[0]]));
  } else {
    positions = [];
  }

  return (
    <MapContainer
      center={[center[1], center[0]]}
      zoomControl={false}
      style={{ height: "100%", width: "100%", zIndex: 0 }}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      dragging={false}
      touchZoom={false}
      attributionControl={false}
      ref={mapRef}
    >
      <TileLayer url={mapURL}/>
      <Polygon className="spill-polygon" positions={positions} />
      <MyComponent mapRef={mapRef} type={type} cords={coordinates} />
    </MapContainer>
  );
}



export default Minimap;