import React from "react";
import { NavLink } from "react-router-dom";

import "./navBarItem.css";


function NavBarItem({text, icon_path, link_path}) {

  return (
    <NavLink
      to={link_path} 
      className={({isActive}) => isActive ? "nav-link active" : "nav-link"
    }>
      <img src={icon_path} alt="Coast Icon" />
      <h1>{text}</h1>
    </NavLink>
  )
}

export default NavBarItem;