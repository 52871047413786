import "./pageController.css";



function findMin(x, y) {
  if (x < y) {
    return x;
  } else {
    return y;
  }
}

function PageController({ page, setPage, rowsToShow, catalogLength, scrollToTop }) {

  function handlePageUp() {
    if (page * rowsToShow >= catalogLength) {
      return;
    }
    setPage(page + 1);
    scrollToTop();
  }

  function handlePageDown() {
    if (page === 1) {
      return;
    }
    setPage(page - 1);
    scrollToTop();
  }

  return (
    <>
      <div className="pageController-div">
        
        <p>
          <b>{1 + (page-1) * rowsToShow}-{findMin(catalogLength, page * rowsToShow)}</b> of {catalogLength}
        </p>

        <div>
          <button onClick={handlePageDown}>
            <span>
              <i className="bi bi-arrow-left" />
            </span>
          </button>
          <button onClick={handlePageUp}>
            <span>
              <i className="bi bi-arrow-right" />
            </span>
          </button>
        </div>

      </div>
    </>
  );
}



export default PageController;