import { Link } from 'react-router-dom';

import "./spillSelectionMenu.css";

function SpillSelectionMenu({ handleSubmit, id, setId }) {

  return (
    <div className="spill-selection-div">
          
      <p>
        If you want to analyze an oil spill, you must first select one.
        <br/><br/>
        You can do this by selecting a spill from the <Link to="/ocean">Oil in the Sea</Link> or <Link to="/catalog">Oil Spills Catalog</Link> sections.
        <br/><br/>
        Alternatively, you can paste a spill ID here and directly analyze it:
      </p>

      <form onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}>
        <input
          type="text"
          placeholder="Paste spill ID here"
          value={id === null ? '' : id}
          onChange={(event) => setId(event.target.value)}
        />
        <input
          className="analyze-button"
          type="submit"
          value="Inspect"
        />
      </form>

    </div>
  );
}

export default SpillSelectionMenu;
