// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-buttons-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-buttons-div button {
    padding: 0.3em 1em;
    border-radius: 0.2em;
}

/* SEARCH BUTTON */
.search-button {
    background-color: rgba(111, 192, 214, 0.3);
    border: 1px solid var(--dark_blue);
    color: var(--dark_blue);
    cursor: pointer;
}
.search-button:hover {
    background-color: rgba(111, 192, 214, 0.6);
}

/* RESET BUTTON */
.reset-button {
    background-color: rgba(192, 97, 97, 0.3);
    border: 1px solid rgb(173, 46, 46);
    color: rgb(173, 46, 46);
    cursor: pointer;
}
.reset-button:hover {
    background-color: rgba(192, 97, 97, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/assets/formButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA,kBAAkB;AAClB;IACI,0CAA0C;IAC1C,kCAAkC;IAClC,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,0CAA0C;AAC9C;;AAEA,iBAAiB;AACjB;IACI,wCAAwC;IACxC,kCAAkC;IAClC,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,wCAAwC;AAC5C","sourcesContent":[".form-buttons-div {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}\r\n\r\n.form-buttons-div button {\r\n    padding: 0.3em 1em;\r\n    border-radius: 0.2em;\r\n}\r\n\r\n/* SEARCH BUTTON */\r\n.search-button {\r\n    background-color: rgba(111, 192, 214, 0.3);\r\n    border: 1px solid var(--dark_blue);\r\n    color: var(--dark_blue);\r\n    cursor: pointer;\r\n}\r\n.search-button:hover {\r\n    background-color: rgba(111, 192, 214, 0.6);\r\n}\r\n\r\n/* RESET BUTTON */\r\n.reset-button {\r\n    background-color: rgba(192, 97, 97, 0.3);\r\n    border: 1px solid rgb(173, 46, 46);\r\n    color: rgb(173, 46, 46);\r\n    cursor: pointer;\r\n}\r\n.reset-button:hover {\r\n    background-color: rgba(192, 97, 97, 0.6);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
