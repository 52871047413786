import React from "react";

import "./downloadForm.css";



function DownloadForm({ handleDownloadRequest }) {
  return (
    <div className="download-form-div">
      <div>
        <p>Download full Shapefile</p>
        <button onClick={() => handleDownloadRequest("Shapefile")}>Download</button>
      </div>

      <p className="or">or</p>

      <div>
        <p>Download .shp file</p>
        <button onClick={() => handleDownloadRequest(".shp")}>Download</button>
      </div>

      <div>
        <p>Download .shx file</p>
        <button onClick={() => handleDownloadRequest(".shx")}>Download</button>
      </div>

      <div>
        <p>Download .dbf file</p>
        <button onClick={() => handleDownloadRequest(".dbf")}>Download</button>
      </div>

      <div>
        <p>Download .prj file</p>
        <button onClick={() => handleDownloadRequest(".prj")}>Download</button>
      </div>
    </div>
  );
}

export default DownloadForm;